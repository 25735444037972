<template>
    <div class="factorydetail">
      <nav-header :navs="2" :navsitems="1"/>
      <div class="contents">
        <div class="in-banner" style="border-bottom:1px solid #dcdcdc">
          <img
            src="http://www.xyzniot.com:90/uploads/20231226/963e6eb7870afd2beaac5f2eb114f530.jpg"
            width="100%"
            height="auto"
          />
        </div>
          <div class="pageBox waps" >
            <div class="pagetits">芯远智能云简介</div>
            <div class="gsjs clearfix animation srcospcur">
              <div>
                <div style="text-indent: 2rem">芯远智能物联网平台提供安全可靠的设备连接通信能力，支持设备数据采集上云，规则引擎流转数据和云端数据下发设备端。此外，也提供方便快捷的设备管理能力，支持物模型定义，数据结构化存储，和远程调试、监控、运维。</div>
                <div style="text-indent: 2rem">智慧城市成为全球城市建设发展的未来方向，带动了与之相关的产业，及关注和更高的要求，如智能建筑能耗。通过自动化的监测平台，能源管理对能耗进行管理和使用，以实现节约运行和管理费用，促进各智能子系统资源共享，协同发展，达到提升能源使用效率。因此，物联网技术将发挥巨大作用。</div>
              </div>
              <div class="pagetit">云平台简介</div>
              <p class="lit_name">功能：</p>
              <div style="text-indent: 2rem">
                综合能源管理系统主要针对于分布全国不同省、地市或同地市不同区域的连锁商业、集团、百货、门店、物业、院校等分布式集团性质客户设计。
                物联网云平台通过在各个本地变电所或配电室等现场安装多功能电力计量仪表；在各用能现场可安装水表，气表等各类仪表，
                经网关采用无线（3G/4G）或有线的方式将采集的仪表数据上传至公司租赁的云服务器上，并将数据进行集中存储、统一管理。
                具有权限的用户可通过PC、PAD、手机等各类终端设备访问数据、接收告警信息，方便管理者及时知晓各个点位的用能情况，及时管控各个点位用能。
              </div>
              <p class="lit_name">应用场所：</p>
              <div>
                <p>（一）分布式集团办公建筑（商务办公、国家机关办公建筑等）；</p>
                <p>（二）分布式连锁商业建筑（商场、金融机构建筑等）；</p>
                <p>（三）分布式连锁旅游建筑（宾馆饭店、娱乐场所等）；</p>
                <p>（四）多分支科教文卫建筑（文化、教育、科研、医疗卫生、体育建筑等）；</p>
                <p>（五）集团通信建筑（邮电、通信、广播、电视、数据中心等）；</p>
                <p>（六）集团交通运输建筑（机场、车站、码头建筑等）。</p>
              </div>
              <div  class="pagetit">综合能源管理系统结构</div>
              <div style="text-align: center">
                <img
                  alt=""
                  src="http://www.jiukaist.com/images/platpht/1690189939175.jpg"
                  class="fluidbox__thumb"
                  style="opacity: 1;width: auto;height:auto"
                />
              </div>
              <div class="pagetit">综合能源管理系统功能</div>
              <div style="text-indent: 2rem">
                  登陆成功后，默认打开能源管理云平台的“平台概括”界面；能源管理云平台主要包括“智慧大屏”，
                  “智慧监测”，“智慧能源”，“智慧运维” ，“档案管理”等功能模块。能源管理云平台支持亮色、暗色两种主题颜色切换，支持中英文两种语言切换。
              </div>
              <p class="lit_name">智慧大屏：</p>
              <div class="font_img">
                <div style="text-indent: 2rem">登陆成功后，默认打开能源管理云平台的“平台概括”界面展示当前登录用户管理全部建筑总用能情况，其中包括平台运行状态，地图导航，当月能耗折算，各能耗逐时、逐月曲线和当日，当月能耗同比分析滚动显示。</div>
                <div>
                  <img
                      alt=""
                      src="../assets/img/screen.png"
                      class="fluidbox__thumb"
                  />
                </div>
              </div>
              <p class="lit_name">智慧监测：</p>
              <div class="font_img">
                <div style="text-indent: 2rem">智慧监测模块包括电、气、水、环控、储能、光伏空调等用能概况，包括用能统计，设备监测、需量分析、告警信息、能流图等功能，主要各综合能源用能监测情况。</div>
                <div>
                  <img
                      alt=""
                      src="../assets/img/jc.png"
                      class="fluidbox__thumb"
                  />
                </div>
              </div>
              <p class="lit_name">智慧能源：</p>
              <div class="font_img">
                <div style="text-indent: 2rem">智慧能源模块包括电、气、水、环控、储能、光伏空调等用能概况，包括用能统计，设备监测、需量分析、告警信息、能流图等功能，主要统计各综合能源用能情况。</div>
                <div>
                  <img
                      alt=""
                      src="../assets/img/nytj.png"
                      class="fluidbox__thumb"
                  />
                </div>
              </div>
              <p class="lit_name">智慧运维：</p>
              <div class="font_img">
                <div style="text-indent: 2rem">智慧运维模块实时监测数据、智能分析和预测，提供全面的设备运行状态和性能评估，以便及时发现问题、预防故障，并优化设备的运行效率和可靠性。</div>
                <div>
                  <img
                      alt=""
                      src="../assets/img/zhyw.png"
                      class="fluidbox__thumb"
                  />
                </div>
              </div>

            </div>
            <div class="xqpage">
              <!--
              <div class="res" @click="backs()">返回</div>-->
            </div>
          </div>
      </div>
      <nav-footer />
    </div>
  </template>
     <script>
  import NavHeader from "@/components/NavHeaders.vue";
  import NavFooter from "@/components/NavFooter.vue";
  export default {
    name: "about",
    data() {
      return {
        Factorys: {
            title:'',
            maincontent:''
        },
        id: '',
        currentPage: 1, // 当前页码
        pageSize: 12, // 每页显示的行数
        total: 0,
      };
    },
    components: {
      NavHeader,
      NavFooter,
    },
    mounted() {},
    created() {
      // let self = this;
      document.documentElement.scrollTop = "0px";
      //self.init();
    },
    methods: {
      backs(){
         history.go(-1)
      },
      init() {
        let self = this;
        this.$api.mall
          .getNewdetail({
            id: self.id,
          })
          .then((ress) => {
            let res = ress.data.data;
            console.log(res, "Ssbbb");
            self.Factorys = res;
          });
      },
    },
  };
  </script>
    <style lang="scss" scoped>
  .factorydetail{
    width: 100%;
    overflow:hidden;
    margin: 0px auto;
    .v-enter {
      opacity: 0;
      transform: translateY(150px);
    }
    .v-enter-active {
      transition: all 1s ease;
    }
    @media screen and(max-width:768px){
      .contents{
        padding-top:60px!important;
      }
      .pageBox{
        line-height:30px!important;
        padding:10px!important;
        width:calc(100% - 20px)!important;
      }
      .waps{
        max-width:unset!important;

      }
      .fluidbox__thumb{
        width: 100%!important;

      }
      .gsjs{
        width: 100%!important;
      }
      .font_img{
        display: block!important;
        div{
          width: 100%!important;
          padding:0!important;
        }
        img{
          width: 100%!important;
        }
      }
    }
    .contents {
      width: 100%;
      overflow: hidden;
      margin: 0px auto;
      padding-top: 75px;
      .xqpage {
        width: 100%;
        margin-top: 30px;
        overflow: hidden;
        border-top: 1px dashed #dcdcdc;
        .res {
          display: block;
          margin-top: 30px;
          margin-bottom: 20px;
          width: 80px;
          line-height: 30px;
          border-bottom: 1px solid #72a3ce;
          background: #005bac;
          color: #fff;
          cursor: pointer;
          font-size: 12px;
          text-align: center;
        }
      }
      .el-pages {
        width: 100%;
        margin: 20px auto;
        overflow: hidden;
        text-align: center;
      }
      .waps {
        width: 90%;
        max-width: 1200px;
        overflow: hidden;
        margin: 0 auto;
      }
      .pageBox {
        line-height: 32px;
        padding: 4% 0 2%;
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;
        .gsjs {
          transform: translate(0, 0px);
          width: 1200px;
          overflow: hidden;
          margin: 0px auto;

          opacity: 1;
          transition: 1.3s;
          -webkit-transition: 1.3s;
          .imgs {
            float: left;
            width: 420px;
            overflow: hidden;
          }
          .txts {
            float: right;
            width: calc(100% - 480px);
            line-height: 2em;
            font-family: 微软雅黑;
            font-size: 16px;
            color: #000000;
          }
          .txt {
            float: right;
            width: calc(100% - 480px);
            line-height: 1.5em;
          }
        }
        .pagetits {
          font-size: 26px;
          text-align: center;
          font-weight: bold;
          line-height: 1.2em;
          padding-bottom: 3%;
          color: #333333;
        }

        .pagetit {
          font-size: 20px;
          text-align: center;
          font-weight: bold;
          margin: 10px 0;
          text-align: center;
          color: #207dba;
        }
        .lit_name{
          font-size: 16px;
          font-weight: 600;
        }

        .font_img{
          display: flex;
          div{
            width: 50%;
            padding:0 10px;
          }
          img{
            width: 350px;
          }
        }
      }
      .in-banner {
        width: 100%;
        overflow: hidden;
        margin: 0px auto;
      }
    }
  }
  </style>
