/**
 * 商城模块接口列表
 */
import axios from '@/api/http.js'; // 导入http中创建的axios实例
import base from './base'; // 导入接口域名列表
import qs from 'qs'; // 根据需求是否导入qs模块

// import qs from 'qs'; // 根据需求是否导入qs模块

// get传参格式
// axios.get('demo/url', {
//     params: {
//         id: 18,
//         name: 'jay',
//     },
//    timeout: 1000,
//   ...//其他相关配置
// })

// post传参格式
// axios.post('demo/url', {
//     id: 18,
//     name: 'jay',
// },{
//    timeout: 1000,
//     ...//其他相关配置
// })

const mall = {
    getBannerList(params) {
        return axios.post(`${base.sq}/api/Banner/getList`,qs.stringify(params));
    },
    getNews(params) {
        return axios.post(`${base.sq}/api/News/getList`,qs.stringify(params));
    },
    getNewdetail(params) {
        return axios.post(`${base.sq}/api/News/getDetail`,qs.stringify(params));
    },
    // 退出
    getFactory(params) {
        return axios.post(`${base.sq}/api/Factory/getList`,qs.stringify(params));
    },
    // 注册
    getHonor(params) {
        return axios.post(`${base.sq}/api/Honor/getList`,qs.stringify(params));
    },
    getLeader(params) {
        return axios.post(`${base.sq}/api/Leader/getList`,qs.stringify(params));
    },
    getExample(params) {
        return axios.post(`${base.sq}/api/Example/getList`,qs.stringify(params));
    },
    sendAliDaYuAuthCode(params) {
        return axios.post(`${base.sq}/api/Common/sendAliDaYuAuthCode`,qs.stringify(params));
    },
    getProductList(params) {
        return axios.post(`${base.sq}/api/Product/getList`,qs.stringify(params));
    },
    getProductDetail(params) {
        return axios.post(`${base.sq}/api/Product/getDetail`,qs.stringify(params));
    },
    getResolve(params) {
        return axios.post(`${base.sq}/api/Resolve/getList`,qs.stringify(params));
    },
    //getPickList
    getResolveDetail(params) {
        return axios.post(`${base.sq}/api/Resolve/getDetail`,qs.stringify(params));
    },
    Messageadd(params) {
        return axios.post(`${base.sq}/api/Message/add`,qs.stringify(params));
    },
    getSpeakList(params) {
        return axios.post(`${base.sq}/api/Speak/getList`,qs.stringify(params));
    },
    speaAdd(params) {
        return axios.post(`${base.sq}/api/Speak/add`,qs.stringify(params));
    },
    getPickUserList(params) {
        return axios.post(`${base.sq}/api/Speak/getPickUserList`,qs.stringify(params));
    },
    getListSticks(params) {
        return axios.post(`${base.sq}/api/Sticks/getList`,qs.stringify(params));
    },
    stickUserList(params) {
        return axios.post(`${base.sq}/api/Sticks/getUserList`,qs.stringify(params));
    },
    stickDel(params) {
        return axios.post(`${base.sq}/api/Sticks/del`,qs.stringify(params));
    },
    stickAdd(params) {
        return axios.post(`${base.sq}/api/Sticks/add`,qs.stringify(params));
    },
    stickCommitList(params) {
        return axios.post(`${base.sq}/api/Commit/getList`,qs.stringify(params));
    },
    getPickListstick(params) {
        return axios.post(`${base.sq}/api/Commit/getPickList`,qs.stringify(params));
    },
    getCommitReplyUserList(params) {
        return axios.post(`${base.sq}/api/Commit/getReplyUserList`,qs.stringify(params));
    },

    stickCommitdel(params) {
        return axios.post(`${base.sq}/api/Commit/del`,qs.stringify(params));
    },
    stickCommitadd(params) {
        return axios.post(`${base.sq}/api/Commit/add`,qs.stringify(params));
    },
    getUserInfo(params) {
        return axios.post(`${base.sq}/api/user/index`,qs.stringify(params));
    },
    getresources(params) {
        return axios.post(`${base.sq}/api/Resources/getList`,qs.stringify(params));
    },
    //获取产品中心列表
    getCategory() {
        return axios.get(`${base.sq}/api/Product/getCategory`);
    },
    //获取产品系列详情
    getTypeInfo(params) {
        return axios.post(`${base.sq}/api/Typeinfo/getDetail`,qs.stringify(params));
    },
    //index
    // 获取用户信息
    // 加入购物车
    addCart(params) {
        return axios.post('api/carts', params);
    },
    // 获取购物车列表
    getCart() {
        return axios.get('api/carts');
    },
    // 更新购物车数量和单选状态
    updateCart(id, params) {
        return axios.put(`api/carts/${id}`, params);
    },
    // 删除购物车商品
    deleteCart(id) {
        return axios.delete(`api/carts/${id}`);
    },
    // 全选购物车
    allCheckCart(url) {
        return axios.put(`api/${url}`);
    },
    // 获取购物车商品数量
    getCartSum() {
        return axios.get('api/carts/products/sum');
    },
    // 获取商品信息
    getProductInfo(id) {
        return axios.get(`api/products/${id}`);
    },
    // 获取收货地址
    getAddress() {
        return axios.get('api/shippings');
    },
    // 收货地址增删改
    handleAddress(method, url, params) {
        return axios[method](url, params);
    },
    // 提交订单
    submitOrder(params) {
        return axios.post('api/orders', params);
    },
    // 获取订单详情
    getOrder(id) {
        return axios.get(`api/orders/${id}`);
    },
    // 获取订单详情
    getOrderList(params) {
        return axios.get('api/orders', { params });
    },
    // 提交支付
    submitPay(params) {
        return axios.post('api/pay', params);
    }
}

export default mall;
