/**
 * 路由封装
 * 路由懒加载方式一：
 *    1. component: resolve => require(['../pages/Index.vue'],resolve)  // 路由懒加载
 * 路由懒加载方式二：
 *    1. 需要添加 syntax-dynamic-import (opens new window)插件，才能使 Babel 可以正确地解析语法,`cnpm install --save-dev @babel/plugin-syntax-dynamic-import`
 *    2. component: () => import('../pages/Cart.vue'), // 路由懒加载
 */
// 导入vue和vue-router
import Vue from 'vue'
import Router from 'vue-router'
// 导入页面组件
// import Login from '../pages/Login'
import Home from '../pages/Home'
import Index from '../pages/Index'
// import Product from '../pages/Product'
import Detail from '../pages/Detail'
import newdetail from '../pages/newdetail'
import Receipt from '../pages/Receipt'
import Reply from '../pages/Reply'
import Speak from '../pages/Speak'
import Posted from '../pages/Posted'
import SpeakReply from '../pages/SpeakReply'
import StickReply from '../pages/StickReply'
import About from '../pages/about'
import Factory from '../pages/factory'
import Companynew from '../pages/Companynew'
import buildings from '../pages/buildings'
import medical from '../pages/medical'
import industry from '../pages/industry'
import yunplat from '../pages/yunplat'
import yunshow from '../pages/yunshow'
import product_type_list from '../pages/product_type_list'
import product_centers from '../pages/product_centers'

import prod_detail from '../pages/prod_detail'
import cm_city from '../pages/cm_city'
import cm_industry from '../pages/cm_industry'
import cm_agriculture from '../pages/cm_agriculture'
import cm_detail from '../pages/cm_detail'
import contact from '../pages/contact'
import solutionPage from '../pages/solutionPage'
import casePage from '../pages/casePage'
//product_type_list


//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// 加载路由插件
Vue.use(Router)
// 导出路由
export default new Router({
  // 路由规则
  routes: [
    // 首页
    {
      path: '/',
      name: 'home',
      component: Home,
      redirect: '/index', // 重定向
      // 子路由
      children: [
        {
          path: 'index',
          name: 'index',
          component: Index,
        },
        {
          path: 'contact',
          name: 'contact',
          component: contact,
        },
          // /:secondid/:thirdid
        {
          path: 'product_type_list/:firstid',
          name: 'product_type_list',
          component: product_type_list,
        },
        {
          path: 'newdetail/:id',
          name: 'newdetail',
          component: newdetail,
        },
        {
          path: 'product_centers',
          name: 'product_centers',
          component: product_centers,
        },
        {
          path: 'cm_detail/:id',
          name: 'cm_detail',
          component: cm_detail,
        },
        {
          path: 'cm_agriculture',
          name: 'cm_agriculture',
          component: cm_agriculture,
        },
        {
          path: 'cm_industry',
          name: 'cm_industry',
          component: cm_industry,
        },
        {
          path: 'cm_city',
          name: 'cm_city',
          component: cm_city,
        },
        {
          path: '/prod_detail/:id',
          name: 'prod_detail',
          component: prod_detail,
        },

        {
          path: 'industry',
          name: 'industry',
          component: industry,
        },
        {
          path: 'yunshow',
          name: 'yunshow',
          component: yunshow,
        },
        {
          path: 'yunplat',
          name: 'yunplat',
          component: yunplat,
        },
        {
          path: 'about',
          name: 'about',
          component: About,
        },
        {
          path: 'factory',
          name: 'factory',
          component: Factory,
        },
        {
          path: 'Companynew',
          name: 'Companynew',
          component: Companynew,
        },
        {
          path: 'buildings',
          name: 'buildings',
          component: buildings,
        },
        {
          path: 'medical',
          name: 'medical',
          component: medical,
        },
        {
          path: '/detail/:id',
          name: 'detail',
          component: Detail,
        },
        {
          path: 'product/:id', // 动态路由
          name: 'product',
          // component: Product,
          // component: resolve => require(['../pages/Product.vue'],resolve), // 路由懒加载
          component: () => import('../pages/Product.vue') // 路由懒加载
        },
        {
          path: '/solutionPage',
          name: 'solutionPage',
          component: solutionPage,
        },
        {
          path: '/casePage',
          name: 'casePage',
          component: casePage,
        },

      ]
    },

    {
      path: '/Receipt/:id',
      name: 'Receipt',
      component: Receipt,
    },
    {
      path: '/Reply',
      name: 'Reply',
      component: Reply,
    },
    {
      path: '/Speak',
      name: 'Speak',
      component: Speak,
    },
    {
      path: '/SpeakReply',
      name: 'SpeakReply',
      component: SpeakReply,
    },
    {
      path: '/StickReply',
      name: 'StickReply',
      component: StickReply,
    },
    {
      path: '/Posted',
      name: 'Posted',
      component: Posted,
    },
    // 登录
    {
      path: '/login',
      name: 'login',
      // component: Login
      component: () => import('../pages/Login.vue') // 路由懒加载
    },
  ]
})
