<template>
  <div class="replys">
 
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor"
import ApiCon from '../util/Api/index'
import axios from "axios"
export default {
  name: 'Reply',
  data() {
    return {


    }
  },
  components: {
    VueEditor
  },
  methods: {
  }
}
</script>
<style lang="scss">
</style>