<template>
  <div class="product_centers">
    <nav-header :navs="4" :navsitems="a" />
    <div class="product_type_centers">
      <div class="in-banner">
        <div class="swiper-centers">
          <swiper class="swiper-container" :options="swiperOption">
            <swiper-slide v-for="(item, index) in slideList" :key="index">
              <img :src="item.image" alt="" width="100%" height="auto" />
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
      <div class="product_tit" v-if="$device.windows">产品中心</div>
      <div class="product_type_list">
        <div  v-for="item in cpslideLists"
              :index="item.name"
              :key="item.name"
              class="list-item"
              @click="goPages(item.id)">
          <div class="module-img">
            <img src="../assets/img/wlwcp3.png" width="100%" height="auto" />
          </div>
          <div>{{ item.name }}</div>
        </div>

      </div>
    </div>
    <nav-footer />
  </div>
</template>
  <script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import NavHeader from "@/components/NavHeaders.vue";
import NavFooter from "@/components/NavFooter.vue";
export default {
  name: "product",
  components: {
    NavHeader,
    NavFooter,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      a: "",
      swiperOption: {
        autoplay: true,
        loop: true,
        speed: 2000,
        cubeEffect: {
          shadowOffset: 100,
          shadowScale: 0.6,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      image: "",
      slideList: [
        {
          image: "http://www.jiukaist.com/uploads/20240119/371734531536edb6e8fb43e96f31b776.png",
        },
        {
          image: "http://www.jiukaist.com/uploads/20240119/288cfb6602f3319998368f7fcbb93b78.png",
        },
      ],
      cpslideLists: [

      ],
    };
  },
  created() {
    localStorage.clear();

  },
  mounted() {
    this.getFactorys()
  },
  methods: {
    goPages(val) {
      let self = this;
      self.$router.push("/product_type_list/" + val);
    },
    // 获取产品列表
    getFactorys() {
      let self = this;
      this.$api.mall
          .getCategory()
          .then((response) => {
            let respon = response.data.data;
            console.log(respon,"22222")
            self.cpslideLists = respon;
          });
    },
  },
};
</script>
  <style lang="scss">
.product_centers {
  width: 100%;
  overflow: hidden;
  background: #fff;
  .swiper-button-next:after,
  .swiper-container-rtl .swiper-button-prev:after {
    content: "" !important;
  }
  .swiper-button-prev:after,
  .swiper-container-rtl .swiper-button-next:after {
    content: "" !important;
  }
  .swiper-button-next {
    background: url(../../public/imgs/right-c.png) no-repeat center;
    opacity: 0.6;
    width: 42px;
    height: 42px;
    background-size: 42px;
  }
  .swiper-button-prev {
    background: url(../../public/imgs/left-c.png) no-repeat center;
    width: 42px;
    opacity: 0.6;
    height: 42px;
    background-size: 42px;
  }
  .product_tit{
    width: 100%;
    font-weight: 600;
    font-size: 24px;
    overflow: hidden;
    line-height: 1.8;
    text-align: center;
    margin: 18px auto;
  }
  .in-banner {
    width: 100%;
    overflow: hidden;
    margin: 75px auto auto auto;
  }
  .product_type_list {
    width: 1200px;
    padding: 0px;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0px auto;
    padding: 0.82rem 0 2rem;
    .list-item {
      cursor: pointer;
      width: 280px;
      margin-left: 18px;
      margin-top: 18px;
      line-height: 1.5;
      font-size: 16px;
      float: left;
      padding: 10px;
      background: #f3f3f3;
      box-sizing: border-box;
      text-align: center;
      .module-img{
        width: 210px;
        height: 160px;
        margin: 0px auto;
        text-align: center;
        overflow: hidden;
      }
      img {
        margin: 0px auto;
      }
    }
  }
  .product_type_centers {
    width: 100%;
    padding: 0px;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0px auto;
  }
  @media screen and(max-width:768px){
    .in-banner{
      margin:60px auto auto auto!important;
    }
    .product_type_list{
      width: calc(100% - 20px);
    }
    .list-item{
      width: 100%!important;
      margin-left: 0!important;
      margin-top: 10px!important;
      float: unset!important;
      padding: 10px;
    }
  }
}
</style>
