<template>
  <div class="commits"></div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import ApiCon from "../util/Api/index";
import axios from "axios";
export default {
  name: "Reply",
  data() {
    return {
      List: [],
    };
  },
  created() {
    this.ApiCon = new ApiCon(this.$store);
    this.baseUrl = this.ApiCon.getApiUrl();
  },
  mounted() {
    this.ApiCon = new ApiCon(this.$store);
    this.ApiConUrl = this.ApiCon.getApiUrl();
  },
  components: {
    VueEditor,
  },
  methods: {},
};
</script>
<style lang="scss">
</style>