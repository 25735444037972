<template>
  <div class="receipt-form">
   

  </div>
</template>
<script>
//import { Swiper, SwiperSlide } from "vue-awesome-swiper";
//import "swiper/css/swiper.css";
import { VueEditor } from "vue2-editor"
import ApiCon from '../util/Api/index'
import axios from "axios"
export default {
  name: 'detail',
  data() {
    return {
      id: this.$route.params.id,
    
    };
  },
  components: {
    VueEditor,
    //Swiper,
    //SwiperSlide
  },
  mounted() {
    this.ApiCon = new ApiCon(this.$store)
    this.ApiConUrl = this.ApiCon.getApiUrl()
  },
  created() {
 
  },
  methods: {
  }
};
</script>
<style lang="scss">

</style>