import VueDB from '../../util/vue-db/vue-db-long'

let DB = new VueDB()

class ApiCon{
    constructor(store){
        var ApiCon = DB.getItem('Api-Con').toJson()
        if(!ApiCon){
          DB.setItem('Api-Con', JSON.stringify(ApiCon = {}))
        }
    
        this.$store = store
        this.ApiConDB = ApiCon
    }
    gets(key) {
        return this.ApiConDB[key]
     }
    getApiUrl(){
        return "https://sosunlive.com"
    }
    upData() {
        DB.setItem('Api-Con', JSON.stringify(this.ApiConDB))
    }
    addPage(value){
      if(!value){
        return
        }        
      this.ApiConDB['pages'] = value
      this.upData()
    }

    addToken(value){
      if(!value){
        return
        }        
      this.ApiConDB['token'] = value
      this.upData()
    }

    addLabel(value){
      if(!value){
        return
        }        
      this.ApiConDB['label'] = value
      this.upData()
    }
}

export default ApiCon