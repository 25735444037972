/**
 * 接口域名的管理
 * 通过base.js来管理我们的接口域名，不管有多少个都可以通过这里进行接口的定义。即使修改起来，也是很方便的。
 * 暂时采用在：vue.config.js中配置devServer
 */
 const base = {
  sq: 'http://www.xyzniot.com:90/',
  bd: 'http://www.xyzniot.com:90/'
}

export default base;
