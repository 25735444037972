<template>
  <div class="factory">
    <nav-header :navs="8" :navsitems="a" />
    <div class="contents">
      <div class="in-banner">
        <img
          src="http://www.jiukaist.com/uploads/20231104/f09e31dc7fb0e9689b3c42bf43ec0df3.png"
          width="100%"
          height="auto"
        />
      </div>
      <div class="pageBox waps">
        <div class="pagetits">联系我们</div>
        <div class="gsjs clearfix animation srcospcur">
          <el-form
            ref="loginForms"
            :model="loginForm"
            autocomplete="on"
            label-position="left"
            :rules="rules"
            style="margin-top: 12px; margin-bottom: 12px"
          >
            <el-form-item label="姓名：" prop="name" style="margin-top: 12px">
              <el-input
                v-model="loginForm.name"
                clearable
                placeholder="请输入姓名"
                size="large"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="性别：" style="margin-top: 12px">
              <el-radio v-model="loginForm.sex" label="0">男</el-radio>
              <el-radio v-model="loginForm.sex" label="1">女</el-radio>
            </el-form-item>
            <el-form-item label="联系电话：" prop="phone">
              <el-input
                v-model="loginForm.phone"
                placeholder="请输入联系电话"
                clearable
                size="large"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="E-mail：" style="margin-top: 12px">
              <el-input
                v-model="loginForm.email"
                placeholder="请输入邮箱地址"
                clearable
                size="large"
              >
              </el-input>
            </el-form-item>

            <el-form-item
              label="联系地址："
              prop="address"
              style="margin-top: 12px"
            >
              <el-input
                v-model="loginForm.address"
                placeholder="请输入联系地址："
                clearable
                size="large"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="咨询内容：" style="margin-top: 12px">
              <el-input
                v-model="loginForm.content"
                clearable
                placeholder="请输入咨询内容："
                type="textarea"
              >
              </el-input>
            </el-form-item>

            <el-form-item style="margin-top: 12px">
              <el-button
                type="primary"
                @click.stop.prevent="Messageadds('loginForms')"
                >提交</el-button
              >
              <el-button style="margin-left: 12px" @click.stop.prevent="reset()"
                >重置</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <nav-footer />
  </div>
</template>
   <script>
import NavHeader from "@/components/NavHeaders.vue";
import NavFooter from "@/components/NavFooter.vue";
export default {
  name: "about",
  data() {
    return {
      a: "",
      loginForm: {
        name: "",
        phone: "",
        address: "",
        content: "",
        sex: "0",
        email: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        phone: [
          {
            required: true,
            message: "请输入正确的手机号码",
            trigger: "blur",
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
          },
        ],
        address: [
          {
            required: true,
            message: "请输入联系地址",
            trigger: "blur",
          },
        ],
      },
      Factorys: [],
      currentPage: 1, // 当前页码
      pageSize: 12, // 每页显示的行数
      total: 0,
    };
  },
  components: {
    NavHeader,
    NavFooter,
  },
  mounted() {},
  created() {
    let self = this;
    document.documentElement.scrollTop = "0px";
    // self.getFactorys();
  },
  methods: {
    reset() {
      let self = this;
      self.loginForm = {
        name: "",
        phone: "",
        address: "",
        content: "",
        sex: "0",
        email: "",
      };
    },
    Messageadds(formName) {
      let self = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$api.mall
            .Messageadd({
              name: self.loginForm.name,
              phone: self.loginForm.phone,
              sex: self.loginForm.sex,
              email: self.loginForm.email,
              addres: self.loginForm.address,
              content: self.loginForm.content,
            })
            .then((res) => {
              if (res.code == 1) {
                self.$message.success("提交成功");
                self.reset();
              } else {
                self.$message.error(res.msg);
              }
            });
        } else {
          self.$message.error("请填写信息");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form-item__label {
  color: #333;
}
input,
button,
textarea {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
}
.factory {
  width: 100%;
  overflow: hidden;
  margin: 0px auto;
  .v-enter {
    opacity: 0;
    transform: translateY(150px);
  }
  .v-enter-active {
    transition: all 1s ease;
  }
  @media screen and(max-width:768px){
    .contents{
      padding-top: 60px!important;
    }
    .waps{
      width: calc(100% - 30px)!important;
      max-width: unset!important;
    }
    .pageBox{
      padding: 15px 0!important;
    }
    .Factorys-items{
      width: 47%!important;
      height: 140px!important;
      margin-left: 10px!important;
      margin-bottom:15px!important;
    }
    .factory_item{
      padding: 10px!important;
      width: calc(100% - 20px)!important;
      height: calc(100% - 20px)!important;
    }
    .text{
      width: calc(100% - 20px)!important;
      left: 10px!important;
    }
  }
  .contents {
    width: 100%;
    overflow: hidden;
    margin: 0px auto;
    padding-top: 75px;
    .el-pages {
      width: 100%;
      margin: 20px auto;
      overflow: hidden;
      text-align: center;
    }
    .waps {
      width: 90%;
      max-width: 680px;
      overflow: hidden;
      margin: 0 auto;
    }
    .pageBox {
      padding: 4% 0 2%;
      width: 100%;
      overflow: hidden;
      box-sizing: border-box;
      .Factorys-centers {
        width: 100%;
        overflow: hidden;
        margin: 0px auto;
        box-sizing: border-box;
        padding: 8px;
        .Factorys-items {
          float: left;
          display: block;
          width: 24%;
          position: relative;
          height: 220px;
          margin-left: 1%;
          margin-top: 12px;
          box-sizing: border-box;
          .text {
            position: absolute;
            bottom: 0px;
            width: 100%;
            left: 0px;
            height: 38px;
            color: #fff;
            text-align: center;
            background-color: rgba(0, 0, 0, 0.5);
            line-height: 38px;
          }
        }
      }
      .gsjs {
        min-height: 100px;
        transform: translate(0, 0px);
        opacity: 1;
        transition: 1.3s;
        -webkit-transition: 1.3s;
        .imgs {
          float: left;
          width: 420px;
          overflow: hidden;
        }
        .txts {
          float: right;
          width: calc(100% - 480px);
          line-height: 2em;
          font-family: 微软雅黑;
          font-size: 16px;
          color: #000000;
        }
        .txt {
          float: right;
          width: calc(100% - 480px);
          line-height: 1.5em;
        }
      }
      .pagetits {
        font-size: 26px;
        text-align: center;
        font-weight: bold;
        line-height: 1.2em;
        padding-bottom: 3%;
        color: #333333;
      }

      .pagetit {
        font-size: 26px;
        text-align: center;
        font-weight: bold;
        line-height: 1.2em;
        padding-bottom: 3.5%;
        color: #333333;
      }
    }
    .in-banner {
      width: 100%;
      overflow: hidden;
      margin: 0px auto;
    }
  }
}
</style>
