<template>
  <div class="factory">
    <nav-header :navs="4" :navsitems="a"/>
    <div class="contents">
      <div class="waps">
        <div class="top_local_option">
          <span v-if="$device.windows">当前位置： </span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/product_centers' }">产品中心</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: url}">{{menuName}}</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: url}">{{submenuName}}</el-breadcrumb-item>
            <el-breadcrumb-item v-if="kName!=null&&kName!=''" :to="{ path: url}">{{kName}}</el-breadcrumb-item>
            <el-breadcrumb-item>{{Factorys.title}}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="detail_Box">
          <div class="prodisplay" v-loading="loading">
            <div class="swiper-centers" v-if="$device.windows">
              <swiper v-if="Images.length>0" ref="swiper" :options="swiperOption" >
                  <swiper-slide v-for="item in Images">
                    <div class="slide-content" v-if="item.indexOf('http') != -1">
                      <img :src="item" width="100%" height="auto" />
                    </div>
                    <div class="slide-content" v-else>
                      <img :src="`http://www.xyzniot.com:90${item}`" width="100%" height="auto" />
                    </div>
                  </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
            <div class="swiper-centers" v-if="$device.mobile">
              <swiper v-if="Images.length>0" ref="swiper" :options="swiperOption" >
                <swiper-slide v-for="item in Images">
                  <div class="slide-content" v-if="item.indexOf('http') != -1">
                    <img :src="item" width="100%" height="auto" />
                  </div>
                  <div class="slide-content" v-else>
                    <img :src="`http://www.xyzniot.com:90${item}`" width="100%" height="auto" />
                  </div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
              </swiper>
            </div>
            <div class="right_content">
              <p class="discription">{{Factorys.title}}</p>
              <div class="sta_title_li" v-if="Factorys.displaymode"><span class="sta_grade1">显示方式：</span><span class="funstate">{{Factorys.displaymode}}</span></div>
              <div class="sta_title_li" v-if="Factorys.basicfunction"><span class="sta_grade1">基本功能：</span><span class="funstate">{{Factorys.basicfunction}}</span></div>
              <div class="sta_title_li" v-if="Factorys.optionalfunction"><span class="sta_grade1">选配功能：</span><span class="funstate">{{Factorys.optionalfunction}}</span></div>
              <div class="sta_title_li" v-if="Factorys.shapesize"><span class="sta_grade1">外形尺寸(mm)：</span><span class="funstate">{{Factorys.shapesize}}</span></div>
              <div class="sta_title_li" v-if="Factorys.openingsize"><span class="sta_grade1">开孔尺寸(mm)：</span><span class="funstate">{{Factorys.openingsize}}</span></div>
              <div class="sta_title_li">
                <span class="sta_grade1" v-if="Factorys.Installationmethod">安装方式：</span><span class="funstate">{{Factorys.Installationmethod}}</span>
                <span class="sta_grade1" v-if="Factorys.deepin" style="margin-left: 20px">深度：</span><span class="funstate">{{Factorys.deepin}}</span>
              </div>
              <div class="sta_title_li" v-if="Factorys.applicationscope">
                <span class="sta_grade1">应用范围：</span><span class="funstate">{{Factorys.applicationscope}}</span>
              </div>
<!--              <div class="probuy_item_btn">-->
<!--                <el-button type="primary">技术手册</el-button>-->
<!--                <el-button type="danger">样品购买</el-button>-->
<!--              </div>-->
            </div>
          </div>
          <div class="probject">
            <el-tabs v-model="activeName"  @tab-click="handleClick">
              <el-tab-pane label="概述特点" name="first">
                <div style=" width: 100%;"  >
                  <div style=" width: 100%;" v-html="Factorys.maincontent"></div>

                </div>
              </el-tab-pane>
<!--              <el-tab-pane  v-if="Factorys.parametercontent" label="性能参数" name="seconds">-->
<!--                <div style=" width: 100%;" v-html="Factorys.parametercontent"></div>-->

<!--              </el-tab-pane>-->
              <el-tab-pane v-if="Factorys.wirecontent" label="接线方式" name="third">
                <div class="line_type" style=" width: 100%;text-align: center" v-html="Factorys.wirecontent">
                </div>
              </el-tab-pane>
<!--              <el-tab-pane label="相关下载" name="forth">-->
<!--                <div class="contact_list">-->
<!--                  <div class="contact_manual">-->
<!--                    <div class="contact_manual_title">产品说明书</div>-->
<!--                    <div class="contact_manual_li">-->
<!--                      <a>{{Factorys.descritptionfile.title}}</a>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="contact_manual">-->
<!--                    <div class="contact_manual_title">开发手册</div>-->
<!--                    <div class="contact_manual_li">-->
<!--                      <a>{{Factorys.selectionid.title}}</a>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="contact_manual">-->
<!--                    <div class="contact_manual_title">企业简介</div>-->
<!--                    <div class="contact_manual_li">-->
<!--                      <a>安耐杰简介</a>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </el-tab-pane>-->
              <el-tab-pane label="订购方式" name="firth">
                <div class="contact_list">
                  <div class="contact_manual">
                    <div class="contact_manual_title">在线购买</div>
                    <div class="contact_manual_li">
                      【官方淘宝店】：<a href="https://shop111404169.taobao.com/?spm=a230r.7195193.1997079397.2.73b562cbFRFuXw" target="_blank">https://shop111404169.taobao.com/?spm=a230r.7195193.1997079397.2.73b562cbFRFuXw</a>
                    </div>
                  </div>
                  <div class="contact_manual">
                    <div class="contact_manual_title"> 批量采购/定制产品</div>
                    <div class="contact_manual_li">【销售专线】：400  15780 100</div>
                    <div class="contact_manual_li">【联系邮箱】：XYZNIOT_XYZN@163.COM</div>
                  </div>
                  <div class="contact_manual">
                    <div class="contact_manual_title"> 技术咨询</div>
                    <div class="contact_manual_li">技术热线：</div>
                    <div class="contact_manual_li">售后热线：</div>
                  </div>
                  <div class="contact_manual">
                    <div class="contact_manual_title">发货地址：</div>
                    <div class="contact_manual_li">
                      <a href="https://www.amap.com/search?query=%E6%B5%99%E6%B1%9F%E7%9C%81%E5%8F%B0%E5%B7%9E%E5%B8%82%E6%A4%92%E6%B1%9F%E5%8C%BA%E4%B8%8B%E9%99%88%E8%A1%97%E9%81%93%E6%B4%AA%E4%B8%89%E8%B7%AF%E8%81%9A%E6%98%9F%E7%A7%91%E5%88%9B%E5%9B%ADC%E5%8C%BA9%E5%B9%A2&city=330000&geoobj=120.510288%7C28.139925%7C122.87166%7C29.223192&zoom=9.74" target="_blank">浙江省台州市椒江区下陈街道洪三路聚星科创园</a>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
    <nav-footer />
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import NavHeader from "@/components/NavHeaders.vue";
import NavFooter from "@/components/NavFooter.vue";
import "swiper/css/swiper.css";
import "swiper/css/swiper.css";
export default {
  name: "product-newdetail",
  data() {
    return {
      a:'',
      id: this.$route.params.id,
      menuName:localStorage.getItem("menuName"),
      submenuName:localStorage.getItem("submenuName"),
      kName:localStorage.getItem("kName"),
      url:'/product_type_list/'+localStorage.getItem("pid"),
      Factorys: [],
      Images:[],
      activeName:"first",
      swiperOption: {
        autoplay: true,
        loop: true,
        speed: 2000,
        cubeEffect: {
          shadowOffset: 100,
          shadowScale: 0.6,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        loading:true,
      },
    };
  },
  components: {
    NavHeader,
    NavFooter,
    Swiper,
    SwiperSlide,
  },
  mounted() {},
  created() {
    let self = this;
    document.documentElement.scrollTop = "0px";
    self.init();
  },
  methods: {
    backs() {
      history.go(-1);
    },
    init() {
      let self = this;
      // console.log(self);
      this.$api.mall
          .getProductDetail({
            id: self.id,
          })
          .then((ress) => {
            let res = ress.data.data;
            self.Factorys = res;
            this.loading = false
            if(res.images){
              self.Images = res.images.split(',')
            }

            // console.log(self.Images);
          });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },

  },
};
</script>
<style lang="scss" scoped>
.factory {
  width: 100%;
  overflow: hidden;
  margin: 0px auto;
  .v-enter {
    opacity: 0;

    transform: translateY(150px);
  }
  .v-enter-active {
    transition: all 1s ease;
  }
  .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after{
    content: '' !important;
  }
  .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
    content: '' !important;
  }
  .swiper-button-next{
    background: url(../../public/imgs/right-c.png) no-repeat center;
    opacity: 0.6;
    width:42px;
    height: 42px;
    background-size: 42px;
  }
  .swiper-button-prev{
    background: url(../../public/imgs/left-c.png) no-repeat center;
    width: 42px;
    opacity: 0.6;
    height: 42px;
    background-size: 42px;
  }
  @media screen and(max-width:768px){
    .contents{
      padding-top:60px!important;
    }
    .top_local_option{
      background: #f5f7fa!important;
    }
    .prodisplay{
      display: block!important;
      padding-top: 0px!important;
      padding: 10px;
    }
    .swiper-centers{
      width: 100%!important;
    }
    .right_content{
      padding-left: 0px!important;
    }
    .discription{
      font-size: 22px!important;
      font-weight: 600;
      text-align: center;
      padding: 20px 0px!important;
    }
    .sta_title_li{
      margin-bottom: 10px!important;
      font-size: 14px!important;
    }
    .sta_grade1{
      font-size: 14px!important;
    }
    .funstate{
      font-size: 13px!important;
    }
    .probject{
      padding:10px!important;
    }
    .jszb_content_1{
      width: 100%!important;
      padding: 10px 5px;

    }
    .line_type>p{
      img{
        width: auto!important;
        max-width: 100%!important;
      }
    }
  }
  .contents {
    width: 100%;
    overflow: hidden;
    margin: 0px auto;
    padding-top: 80px;
    th{
      background: #79b0e9!important;
      color:#fff;
      padding: 8px 5px;
      font-size: 14px;
      font-weight: 500;
    }
    td{
      padding: 8px 5px;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
    }
    .td{
      font-size: 14px;
      background: #79b0e9!important;
      color: #fff;
      padding: 8px 5px;
    }
    .top_local_option{
      padding: 10px;
      background: #fff;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
    }

    .waps {
      width: 100%;
      max-width:1200px;
      overflow: hidden;
      margin: 0 auto;
      .prodisplay{
        display: flex;
        //align-items: center;
        //justify-content: space-around;
        padding-top: 50px;
        .right_content{
          padding-left: 100px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .discription{
            padding: 0 0 30px;
            //text-align: center;
            font-size: 30px;
            color: #207DBA;
          }

          .probuy_item_btn{
            margin-top: 20px;
            button{
              padding: 12px 20px;
            }
          }
        }

      }
      .swiper-centers {
        width: 580px;
        //margin: 0px auto;
        .slide-content{
          width: 65%;
          margin: 0 auto;
        }
      }
      .sta_grade1{
        font-weight: 600;
        font-size: 16px;
        color: #207DBA!important;
        margin: 15px 0;
      }
      .funstate{
        font-size: 15px;
        letter-spacing: 1px;
        text-indent: 2rem;
        line-height: 1.5rem;
      }
      .sta_title_li{
        font-size: 16px;
        color: #333;
        margin-bottom: 15px;
      }
    }
    .probject{
      padding: 20px 0 50px;

      .contact_list{

        .contact_manual{
          margin-bottom: 35px;
          .contact_manual_title{
            font-size: 16px;
            background: #79b0e9;
            color: #fff;
            padding: 8px 5px;
          }
          .contact_manual_li{
            font-size: 14px;
            padding: 8px 8px;
            border: 1px dashed #ddd;
            border-top: none;
            cursor: pointer;
          }
          .contact_manual_li a:hover{
            color: #409eff;
          }
        }
      }
    }
    .gstd_rules{
      .aim_mark{
        font-size: 13px;
        padding: 5px;
        img{width: 100%!important}
      }
    }
    .jszb_box{
      display: flex!important;
      flex-wrap: wrap!important;
    }
    .jszb_content_1{
      width: 32%;
      padding: 10px 5px;

    }
    .rank_two_title{
      font-size: 15px;
      padding: 5px 0;
      font-weight: bold;
    }
    .jszb_rules>ul>li{
      display: flex;
      font-size: 13px;
      padding: 5px;
      .aim_label{
        width: 110px;
      }
    }
  }
}
</style>
