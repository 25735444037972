<template>
  <div class="index">
    <nav-header :navs="1" />
    <div class="content">
      <div class="swiper-centers">
        <swiper class="swiper-container" :options="swiperOption">
          <swiper-slide v-for="(item, index) in slideList" :key="index">
            <img :src="'http://www.xyzniot.com:90/'+item.image" alt="" width="100%" height="auto" />
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
      <div class="product-center">
        <div class="index-public">
          <div class="title">产品中心</div>
          <div class="public-a" @click="goPages('product_centers','')">查看更多</div>
        </div>
        <swiper ref="swiper" :options="options">
          <template v-for="item in cpslideLists">
            <swiper-slide :index="item.name" :key="item.name">
              <div class="slide-content" @click.stop.prevent="goProPage(item.id,'产品中心')">
                <img :src="`http://www.xyzniot.com:90${item.image}`" width="60px" height="auto" />
                <div class="font">{{ item.name }}</div>
              </div>
            </swiper-slide>
          </template>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
      <div class="i-plan">
        <div class="index-public">
          <div class="title">解决方案</div>
          <div class="public-a" @click="goPages('/solutionPage','')">查看所有解决方案</div>
        </div>
        <div class="index-case-list-box">
          <div class="index-case-inner">
            <div class="left-slide-box">
              <swiper ref="swiperLeft" :options="left_options">
                <swiper-slide>
                  <div class="left-slide-inner" @click.stop.prevent="goPages('/cm_industry','')">
                    <div class="icon icon3"></div>
                    <div class="font">智慧工业</div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="left-slide-inner" @click.stop.prevent="goPages('/cm_city','')">
                    <div class="icon icon1"></div>
                    <div class="font">智慧城市</div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="left-slide-inner" @click.stop.prevent="goPages('/cm_agriculture','')">
                    <div class="icon icon2"></div>
                    <div class="font">智慧养殖业</div>
                  </div>
                </swiper-slide>

              </swiper>
            </div>
            <div class="right-slide-box">
              <swiper ref="swiperThumbs" :options="right_options">
                <swiper-slide>
                  <div class="right-slide-inner" @click.stop.prevent="goPages('/cm_agriculture','')">
                    <img src="../assets/img/cs_3.png">
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="right-slide-inner" @click.stop.prevent="goPages('/cm_city','')">
                    <img src="../assets/img/yz_fw.png">
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="right-slide-inner" @click.stop.prevent="goPages('/cm_industry','')">
                    <img src="../assets/img/gy_2.png">
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </div>

      <div class="i-intro">
        <div class="index-public">
          <div class="title">关于我们</div>
          <div class="public-a" @click="goPages('about','')">查看更多</div>
        </div>
        <div class="w980">
          <div class="video"><img src="../assets/img/companyIMG.png"></div>
          <div class="detail">
            <h2>台州芯远智能科技有限公司</h2>
            <p style="text-indent: 2rem;font-size: 16px;letter-spacing: 1px;line-height: 25px;">
              <span>台州芯远智能科技有限公司于2019年注册成立，位于浙江省台州市，
                是一家物联网产品及其配套软件推广企业。
                公司主要产品分为：物联网数据网关，传感器，输入输出，信号采集，智能照明，物联网开关，通信转换，物联网能耗监测，智慧型采集终端等。</span>
            </p>
          </div>
          <div class="clear"></div>
        </div>
      </div>

      <div class="i-list">
        <div class="w980">
          <div class="i-news">
            <div class="index-public">
              <div class="title">新闻中心</div>
              <div class="public-a" @click="goPages('/newsPage','')">查看所有新闻</div>
            </div>
            <div class="news-single">
              <a @click.stop.prevent="goPages('/detail/'+firstNews.id),''">
                <img v-if="firstNews.image.indexOf('http') != -1"
                     :src="firstNews.image"
                     width=278px height=193
                     :alt="firstNews.title"
                />
                <img v-else
                     :src="`http://www.xyzniot.com:90/${firstNews.image}`"
                     width=278px height=193
                     :alt="firstNews.title"
                />
                <p class="int">{{firstNews.title}}</p>
              </a>
            </div>
            <div class="list fr">
              <ul class="company-news"
                  :title="item.title"
                  @click.stop.prevent="goPages('/detail/'+item.id),''"
                  v-for="(item,index) in newsList"
                  :index="index"
                  :key="index">
                <li v-if="index>0 && index<5" >
                    <span class="fl">{{'0'+index}}</span>
                    <div class="fr">
                      <p class="layui-elip new-titless">{{item.title}}</p>
                      <p>{{item.createtime}}</p>
                    </div>
                </li>
              </ul>
            </div>
            <div class="clear"></div>
          </div>
          <div class="clear"></div>
        </div>
      </div>

    </div>
    <nav-footer />
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import NavHeader from "@/components/NavHeaders.vue";
import NavFooter from "@/components/NavFooter.vue";
import "swiper/css/swiper.css";
import ApiCon from "../util/Api/index";
import "swiper/css/swiper.css";
export default {
  name: "index",
  data() {
    return {
      options: {
        pagination: {
          el: ".case-swiper-pagination",
        },
        loop: true,
        loopedSlides: 4,
        slidesPerView: 4,
        speed: 1000,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        autoplay: {
          delay: 1000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        loopAdditionalSlides: 1,
        on: {
          slideChangeTransitionEnd: function () {
            console.log(this.activeIndex);
          },
        },
      },

      swiperOption: {
        autoplay: true,
        loop: true,
        speed: 4000,
        cubeEffect: {
          shadowOffset: 100,
          shadowScale: 0.6,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },

      cpslideLists: [

      ],
      slideList: [


      ],
      newsList:[],
      firstNews:'',
      left_options:{
        notNextTick: true,
        direction:'vertical',
        setWrapperSize: true,
        freeMode:true,//true则是自由模式，不会自动贴合滑动位置
        loop:true,//循环
        loopedSlides: 3,
        autoplay: {
          delay:3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        loopAdditionalSlides: 1,
        spaceBetween:0,//slide之间的距离，px
        slidesPerView:3 ,//slide可见数量
        mousewheelControl : true,//鼠标滚轮控制
        grabCursor:true,//鼠标变为抓手
        // observer: true,
      },
      right_options:{
        loop: true,
        loopedSlides: 3,
        slidesPerView: 'auto',
        autoplay: false,
        loopAdditionalSlides: 1,
        touchRatio: 0.2,
        centeredSlides: true
      },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    NavHeader,
    NavFooter,
  },
  updated() {
    this.$nextTick(() => {
      const swiperLeft = this.$refs.swiperLeft.$swiper;
      const swiperThumbs = this.$refs.swiperThumbs.$swiper;
      swiperLeft.controller.control = swiperThumbs;

    });
  },
  mounted() {
    this.ApiCon = new ApiCon(this.$store);
    this.ApiConUrl = this.ApiCon.getApiUrl();
    this.getnewsList()

  },
  created() {
    this.ApiCon = new ApiCon(this.$store);
    //this.$layer.msg('你好')
    this.ApiConUrl = this.ApiCon.getApiUrl();
    this.baseUrl = this.ApiConUrl;
    let self = this;
    self.getBannerLists();
    self.getFactorys()
  },
  methods: {
    // 获取产品列表
    getFactorys() {
      let self = this;
      this.$api.mall
          .getCategory()
          .then((response) => {
            let respon = response.data.data;
            console.log(respon,"22222")
            self.cpslideLists = respon;
          });
    },
    goProPage(val,topmenuname){
      let self = this;
      localStorage.setItem('topmenuname',topmenuname)
      self.$router.push("/product_type_list/" + val);
      localStorage.clear();

    },
    goPages(val,topmenuname) {
      localStorage.setItem('topmenuname',topmenuname)
      this.$router.push(val);
    },
    getBannerLists() {
      let self = this;
      this.$api.mall
        .getBannerList({
          limit: 14,
          page: 1,
        })
        .then((ress) => {
          let res = ress.data.data.data;
          self.slideList = res;
        });
    },
    getnewsList() {
      let self = this;
      this.$api.mall
          .getNews({
            limit: 10,
            page: 1,
            type: '',
          })
          .then((ress) => {
            let res = ress.data.data.data;
            self.newsList = res;
            self.firstNews = res[0]
          });
    },
  },
};
</script>
<style lang='scss' scoped>
@import "src/assets/scss/newindex";

</style>
