<template>
  <!-- 顶部导航 -->
<!--      <div class="header">-->
  <div class="header" v-on:mouseleave="handleave(1)">
    <div v-if="$device.windows" class="pc-container-top">
      <div class="nav-topbar">
        <div class="logo">
          <img alt="台州芯远智能科技有限公司" width="150px" src="../assets/img/logo.png"/>
          <div class="logo-text">
            <p>台州芯远智能科技有限公司</p>
            <p style="font-size: 12px;font-weight: 500;margin-top: 5px;letter-spacing: 2px;">物联网整体解决方案提供商</p>
          </div>
        </div>
        <div class="container">
          <div
              class="item-topbar"
              :class="{ active: a == 1, activea: activea == 1 }"
              v-on:mouseover="handleHover(1)"
              @click="goPages('/index', 1, 1)"
          >
            <span :class="{ 'active-line': a == 1 }"> 首页 </span>
          </div>
          <div
              class="item-topbar"
              :class="{ active: a == 2, activea: activea == 2 }"
              v-on:mouseover="handleHover(2)"
          >
            <span :class="{ 'active-line': a == 2 }">芯远智能云平台</span>
            <div class="nav-centers" v-if="b == 2" v-show="showsNav">
              <div
                  class="nav-items"
                  :class="{ navactive: nava == 1 }"
                  v-on:mouseover="handlenav(1)"
                  @click="goPages('/yunplat', 1, 1)"
              >
                <span> 云简介 </span>
              </div>
              <div
                  class="nav-items"
                  :class="{ navactive: nava == 2 }"
                  v-on:mouseover="handlenav(2)"
                  @click="goPages('/yunshow', 1, 2)"
              >
                <span> 云展示 </span>
              </div>
            </div>
          </div>
          <div
              class="item-topbar"
                :class="{ active: a == 3, activea: activea == 3 }"
              v-on:mouseover="handleHover(3)"
          >
            <span :class="{ 'active-line': a == 3 }" @click="goPages('/solutionPage', '3', '')">解决方案 </span>
            <div class="nav-centers" v-if="b == 3" v-show="showsNav">
              <div
                  class="nav-items"
                  :class="{ navactive: nava == 3 }"
                  v-on:mouseover="handlenav(3)"
                  @click="goPages('/cm_city', 3, 3)"
              >
                <span> 智慧城市 </span>
              </div>
              <div
                  class="nav-items"
                  :class="{ navactive: nava == 4 }"
                  v-on:mouseover="handlenav(4)"
                  @click="goPages('/cm_agriculture', 3, 4)"
              >
                <span> 智慧养殖业 </span>
              </div>
              <div
                  class="nav-items"
                  :class="{ navactive: nava == 5 }"
                  v-on:mouseover="handlenav(5)"
                  @click="goPages('/cm_industry', 3, 5)"
              >
                <span> 智慧工业 </span>
              </div>
              <div
                  class="nav-items"
                  :class="{ navactive: nava == 6 }"
                  v-on:mouseover="handlenav(6)"
              >
                <a href="https://manage.xyzniot.com/#/login?redirect=%2Fdashboard" target="_blank" style="color: #333">
                  <span> 智慧农村 </span>
                </a>
              </div>
            </div>
          </div>
          <div
              class="item-topbar"
              style="position: sticky;"
              :class="{ active: a == 4, activea: activea == 4 }"
              v-on:mouseover="handleHover(4)"
          >
            <span :class="{ 'active-line': a == 4 }" @click="goPages('/product_centers', '4', '')"> 产品中心 </span>
            <div class="nav-centers" v-if="b == 4" v-show="showsNav" style="min-width: 260px;left: -100px;">
              <template v-for="item in Factorys">
                <div
                    class="nav-items"
                    :class="{ navactive: nava == item.id }"
                    v-on:mouseover="handlenav(item.id)"
                    :index="item.name" :key="item"
                    @click="goPages('/product_type_list/'+item.id, item.id, item.id)"
                >
                  <span class="li-list-item list-items-title-text"
                       :index="item.id"
                       :key="item.id"
                  >{{item.name}}</span>
                </div>
              </template>
            </div>
          </div>
          <div
              class="item-topbar"
              :class="{ active: a == 5, activea: activea == 5 }"
              v-on:mouseover="handleHover(5)"
          >
            <span :class="{ 'active-line': a == 5 }" @click="goPages('/casePage', '5', '')"> 项目案例 </span>
            <div class="nav-centers" v-if="b == 5" v-show="showsNav">
              <div
                  class="nav-items"
                  :class="{ navactive: nava == 16 }"
                  v-on:mouseover="handlenav(16)"
                  @click="goPages('/buildings', 5, 16)"
              >
                <span> 公共建筑 </span>
              </div>
              <div
                  class="nav-items"
                  :class="{ navactive: nava == 17 }"
                  v-on:mouseover="handlenav(17)"
                  @click="goPages('/medical', 5, 17)"
              >
                <span> 医疗教育 </span>
              </div>
              <div
                  class="nav-items"
                  :class="{ navactive: nava == 18 }"
                  v-on:mouseover="handlenav(18)"
                  @click="goPages('/industry', 5, 18)"
              >
                <span> 工业制造 </span>
              </div>
            </div>
          </div>
          <div
              class="item-topbar"
              :class="{ active: a == 6, activea: activea == 6 }"
              v-on:mouseover="handleHover(6)"
          >
            <span :class="{ 'active-line': a == 6 }" @click="goPages('/Companynew', '6', '')"> 新闻中心 </span>

          </div>
          <div
              class="item-topbar"
              :class="{ active: a == 7, activea: activea == 7 }"
              v-on:mouseover="handleHover(7)"
          >
            <span :class="{ 'active-line': a == 7 }" @click="goPages('/about', '7', '')"> 关于我们 </span>
            <div class="nav-centers" v-if="b == 7" v-show="showsNav">
              <div
                  class="nav-items"
                  :class="{ navactive: nava == 21 }"
                  v-on:mouseover="handlenav(21)"
                  @click="goPages('/about', 7, 21)"
              >
                <span> 公司简介 </span>
              </div>
              <div
                  class="nav-items"
                  :class="{ navactive: nava == 22 }"
                  v-on:mouseover="handlenav(22)"
                  @click="goPages('/factory', 7, 22)"
              >
                <span> 公司环境 </span>
              </div>

            </div>
          </div>
          <div
              class="item-topbar"
              :class="{ active: a == 8, activea: activea == 8 }"
              v-on:mouseover="handleHover(8)"
              @click="goPages('/contact', 8, 8)"
          >
            <span :class="{ 'active-line': a == 8 }"> 联系我们 </span>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="$device.mobile" class="ph-container-top">
      <div class="nav-topbar">
        <div class="logo">
          <img width="120px" alt="" src="../assets/img/logo.png"/>
        </div>
        <div class="right-container">
          <div class="curr_menu_name" @click="showMenus">
            <span>{{topmenuname}}</span>
            <img width="18px" src="../assets/img/list.png" alt="菜单"/>
          </div>
          <div class="menulist" v-show="phmenuShow">
            <div class="item-topbar" @click="openlink('/index','首页')">
              <span> 首页 </span>
            </div>
            <div class="item-topbar" @click="openlink('/yunplat','芯远智能云平台')">
              <span> 芯远智能云平台 </span>
            </div>
            <div class="item-topbar" @click="openlink('/solutionPage','解决方案')">
              <span> 解决方案 </span>
            </div>
            <div class="item-topbar" @click="openlink('/product_centers','产品中心')">
              <span> 产品中心 </span>
            </div>
            <div class="item-topbar" @click="openlink('/casePage','项目案例')">
              <span> 项目案例 </span>
            </div>
            <div class="item-topbar" @click="openlink('/Companynew','新闻中心')">
              <span> 新闻中心 </span>
            </div>
            <div class="item-topbar" @click="openlink('/about','关于我们')">
              <span> 关于我们 </span>
            </div>
            <div class="item-topbar" @click="openlink('/contact','联系我们')">
              <span> 联系我们 </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nav-header",
  props: ["navs", "navsitems"],
  data() {
    return {
      a: 1,
      b: "",
      activenava: "",
      nava: "",
      activea: 1,
      showsNav: false,
      phoneList: [],
      Factorys: [],
      screenWidth:1920,
      phmenuShow:false,
      topmenuname:'首页'
    };
  },
  watch: {
    navs: {
      handler(newVal) {
        //this.b = newVal;
        this.activea = newVal;
        this.a = newVal;
        this.showsNav = true;
      },
      // 立即处理 进入页面就触发
      immediate: true,
      // 深度监听 属性的变化
      deep: true,
    },
    navsitems: {
      handler(newVal) {
        this.activenava = newVal;
        this.nava = newVal;
      },
      // 立即处理 进入页面就触发
      immediate: true,
      // 深度监听 属性的变化
      deep: true,
    },
    screenWidth: {
      handler: function (val) {

        if (val > 1200) {
          // console.log(val+'屏幕宽度大于900px')
        }
      },
      immediate: true,
      deep:true
    },
  },
  created() {
    // console.log(this.$route,'21212')
    if(this.$route.name == 'product_centers' || this.$route.name == 'product_type_list' || this.$route.name == 'newdetail'){
      this.topmenuname = '产品中心'
    }else if(this.$route.name == 'Companynew'){
      this.topmenuname = '新闻中心'
    }else if(this.$route.name == 'solutionPage'){
      this.topmenuname = '解决方案'
    }else if(this.$route.name == 'casePage'){
      this.topmenuname = '项目案例'
    }else if(this.$route.name == 'about'){
      this.topmenuname = '关于我们'
    }else if(this.$route.name == 'contact'){
      this.topmenuname = '联系我们'
    }else if(this.$route.name == 'yunplat'){
      this.topmenuname = '芯远智能云平台'
    }else if(this.$route.name == 'index'){
      this.topmenuname = '首页'
    }
    else {
      this.topmenuname = localStorage.getItem('topmenuname')
    }

  },
  mounted() {
    this.getFactorys();
  },
  methods: {
    handlenav(val) {
      let self = this;
      self.nava = val;
    },
    handleHover(val) {
      let self = this;
      self.a = val;
      self.b = val;
      self.showsNav = true;
    },
    handleave(val) {
      console.log(val)
      let self = this;
      if (self.activea == 1) {
        self.b = "";
        self.nava = "";
        self.showsNav = false;
      } else {
        self.b=''
        self.nava = self.activenava;
      }
      self.a = self.activea;
    },
    goPages(val, a, b) {
      let self = this;
      self.activea = a;
      self.a = a;
      // self.b = a;
      self.nava = b;
      localStorage.setItem("pid",'');//存储一级菜单id
      localStorage.setItem("menuName",'');//存储一级菜单名称
      localStorage.setItem("sid",'');//存储二级菜单id
      localStorage.setItem("submenuName",'');//存储二级菜单名称
      localStorage.setItem("kid",'');//存储三级菜单id
      localStorage.setItem("kName",'');//存储三级菜单名称

      this.$router.push(val);
      location.reload()
    },
    // 获取产品列表
    getFactorys() {
      let self = this;
      this.$api.mall
      .getCategory()
      .then((response) => {
        let respon = response.data.data;
        console.log(respon,"11111")
        self.Factorys = respon;
      });
    },
    showClose(){
      this.phmenuShow = false
    },
    showMenus(){
      this.phmenuShow = !this.phmenuShow
    },
    openlink(val,topmenuname){
      localStorage.setItem("topmenuname",topmenuname);//存储三级菜单名称
      this.$router.push(val);
    }
  },
};
</script>

<style lang="scss" scoped>
// @import "./../assets/scss/base.scss";
// @import "./../assets/scss/config.scss";
.header {
  z-index: 33;
  position: fixed;
  width: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
  background: #fff;
  //}
  .pc-container-top {
    min-width: 1200px;
    margin: 0 auto;
    background: #fff;
    .nav-topbar {
      padding: 0 7%;
      height: 80px;
      .logo {
        float: left;
        height: 80px;
        display: flex;
        align-items: center;
      }
      .logo-text {
        float: left;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-left: 10px;
        p {
          font-size: 16px;
          width: 100%;
          font-weight: 700;
        }
      }

      .container {
        @include flex();
        float: right;
        width: 52%;
        line-height: 80px;
        .active-line {
          position: relative;
          display: inline-block;
          line-height: 32px;
          color: #207DBA;
          border-bottom: 3px solid #d51419;
        }
        .item-topbar {
          font-size: 16px;
          display: inline-block;
          color: #333;
          cursor: pointer;
          position: relative;
        }
      }
      .nav-centers {
        background:#f5f5f5;
        position: absolute;
        top: 80px;
        min-width: 90px;
        left: -20px;
        padding: 0 10px;
        .navactive {
          span {
            height: 30px;
            line-height: 30px;
            margin: 14px auto;
            color: #207DBA;
            border-bottom: 1px solid #207DBA;
            display: inline-block;
          }
        }
        .nav-items {
          text-align: center;
          line-height: 60px;
          cursor: pointer;
        }
      }
      .product-center-area{
        position: fixed;
        top: 80px;
        //min-width: 1200px;
        left: 0;
        display: flex;
        justify-content: center;
        background: #f5f5f5;
        box-shadow: 0 0 10px 0 #ddd;
        z-index: 1;
        .nav-centers-list {
          width: 60%;
          margin: 0 auto;
          background: #f5f5f5;
          display: flex;
          justify-content: center;
          padding-top: 30px;
          padding-bottom: 20px;
          .nav-cs {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            width: 100%;
          }
          .li-list-item {
            cursor: pointer;
          }
          .li-list-item:hover {
            color: #207DBA;
          }
          .li-list-item_1 {
            width: 25%;
            margin-bottom: 15px;
            cursor: pointer;
            position: absolute;
            right: 0;
          }
          .li-list-item_2 {
            width: 32%;
            margin-bottom: 15px;
            cursor: pointer;
          }

          .list-items-title-text {
            display: block;
            color: #666666;
            line-height: 2;
            text-align: left;
            margin-top: 0.17rem;
            transition: 0.3s all;
            font-size: 14px;
          }
          .list-items-title {
            font-size: 17px;
            font-weight: 600;
            color: #333333;
            line-height: initial;
            display: block;
            text-align: left;
            transition: 0.3s all;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  .ph-container-top{
    width: 100%;
    min-width: unset!important;
    .nav-topbar {
      position: relative;
      padding: 0 15px!important;
      width: calc(100% - 30px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo {
        height: 60px;
        display: flex;
        align-items: center;
      }
      .right-container {
        .curr_menu_name{
          display: flex;
          span{
            margin-right: 10px;
          }
        }
        .menulist{
          position: absolute;
          width: auto;
          background: #fff;
          box-shadow: 0 0 10px 0 #ddd;
          right: 15px;
          top: 50px;
        }
        .item-topbar {
          font-size: 14px;
          color: #000;
          letter-spacing: 1px;
          padding: 5px 10px;
          border-bottom: 1px solid #ddd;
        }
      }
    }
  }
}
</style>
