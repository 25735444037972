<template>
  <div class="factorydetail">
    <nav-header :navs="3" :navsitems="a" />
    <div class="contents">
      <div class="in-banner">
        <div class="swiper-centers">
          <swiper class="swiper-container" :options="swiperOption">
            <swiper-slide v-for="(item, index) in slideList" :key="index">
              <img :src="item.image" alt="" width="100%" height="auto" />
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>

      <div class="pageBox waps">
        <div class="pagetits">{{ Factorys.title }}</div>
        <div class="gsjs clearfix animation srcospcur">
          <div v-html="Factorys.maincontent"></div>
        </div>
        <div class="xqpage">
          <div class="res" @click="backs()">返回</div>
        </div>
      </div>
    </div>
    <nav-footer />
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import ApiCon from "../util/Api/index";
import "swiper/css/swiper.css";
import NavHeader from "@/components/NavHeaders.vue";
import NavFooter from "@/components/NavFooter.vue";
export default {
  name: "about",
  data() {
    return {
      a: "",
      Factorys: "",
      id: this.$route.params.id,
      currentPage: 1, // 当前页码
      pageSize: 12, // 每页显示的行数
      total: 0,
      swiperOption: {
        autoplay: true,
        loop: true,
        speed: 4000,
        cubeEffect: {
          shadowOffset: 100,
          shadowScale: 0.6,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      slideList: [
        {
          id: "43",
          image:
            "http://www.jiukaist.com/images/banner/5.png",
        },
        {
          id: "43",
          image:
            "http://www.jiukaist.com/images/banner/4.png",
        },
        {
          id: "43",
          image:
            "http://www.jiukaist.com/images/banner/6.png",
        },
      ],
    };
  },
  components: {
    NavHeader,
    NavFooter,
    Swiper,
    SwiperSlide,
  },
  mounted() {},
  created() {
    let self = this;
    document.documentElement.scrollTop = "0px";
    self.init();
  },
  methods: {
    backs() {
      history.go(-1);
    },
    init() {
      let self = this;
      this.$api.mall
        .getResolveDetail({
          id: self.id,
        })
        .then((ress) => {
          let res = ress.data.data;
          console.log(res, "Ssbbb");
          self.Factorys = res;
        });
    },
  },
};
</script>
  <style lang="scss" scoped>
.factorydetail {
  width: 100%;
  overflow: hidden;
  margin: 0px auto;
  .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after{
      content: '' !important;
  }
  .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
    content: '' !important;
}
  .swiper-button-next{
    background: url(../../public/imgs/right-c.png) no-repeat center;
    opacity: 0.7;
    width: 36px;
    height: 36px;
    background-size: 36px;
  }
  .swiper-button-prev{
    background: url(../../public/imgs/left-c.png) no-repeat center;
    width: 36px;
    opacity: 0.7;
    height: 36px;
    background-size: 36px;
  }
  .v-enter {
    opacity: 0;
    transform: translateY(150px);
  }
  .v-enter-active {
    transition: all 1s ease;
  }
  @media screen and(max-width:768px){
    .contents{
      padding-top: 60px!important;
    }
    .waps{
      max-width: unset!important;
      padding: 10px!important;
    }
    .gsjs{
      width: 100%!important;
    }
  }
  .contents {
    width: 100%;
    overflow: hidden;
    margin: 0px auto;
    padding-top: 75px;
    .xqpage {
      width: 100%;
      margin-top: 30px;
      overflow: hidden;
      border-top: 1px dashed #dcdcdc;
      .res {
        display: block;
        margin-top: 30px;
        margin-bottom: 20px;
        width: 80px;
        line-height: 30px;
        border-bottom: 1px solid #72a3ce;
        background: #005bac;
        color: #fff;
        cursor: pointer;
        font-size: 12px;
        text-align: center;
      }
    }
    .el-pages {
      width: 100%;
      margin: 20px auto;
      overflow: hidden;
      text-align: center;
    }
    .waps {
      width: 90%;
      max-width: 868px;
      overflow: hidden;
      margin: 0 auto;
    }
    .pageBox {
      padding: 4% 0 2%;
      width: 100%;
      overflow: hidden;
      box-sizing: border-box;
      .gsjs {
        transform: translate(0, 0px);
        width: 868px;
        text-align: center;
        overflow: hidden;
        margin: 0px auto;

        opacity: 1;
        transition: 1.3s;
        -webkit-transition: 1.3s;

          img{
            width: auto!important;
            overflow: hidden;
          }
        .txts {
          float: right;
          width: calc(100% - 480px);
          line-height: 2em;
          font-family: 微软雅黑;
          font-size: 16px;
          color: #000000;
        }
        .txt {
          float: right;
          width: calc(100% - 480px);
          line-height: 1.5em;
        }

      }
      .gsjs p::after{
        img{
          width: auto!important;
        }
      }

      .pagetits {
        font-size: 26px;
        text-align: center;
        font-weight: bold;
        line-height: 1.2em;
        padding-bottom: 3%;
        color: #333333;
      }

      .pagetit {
        font-size: 26px;
        text-align: center;
        font-weight: bold;
        line-height: 1.2em;
        padding-bottom: 3.5%;
        color: #333333;
      }
    }
    .in-banner {
      width: 100%;
      overflow: hidden;
      margin: 0px auto;
    }
  }
}
</style>
