<template>
  <div class="factory">
    <nav-header :navs="6" :navsitems="19"/>
    <div class="contents">
      <div class="in-banner">
        <img
          src="http://www.jiukaist.com/images/banner/8.png"
          width="100%"
          height="auto"
        />
      </div>

      <transition>
        <div class="pageBox waps">
          <div class="gsjs clearfix animation srcospcur" v-loading="loading">
            <div class="Factorys-centers">
              <div
                  class="Factorys-items"
                  v-for="(val, index) in Factorys"
                  :key="index"
                  @click="goPages(val.id)"

              >
                <div class="factory_item" :title="val.title">
                  <div style="width: 100%;height: 100%;overflow: hidden">
                    <img v-if="val.image.indexOf('http') != -1"
                         :src="val.image"
                         style="width: 100%; height: auto"
                    />
                    <img v-else
                         :src="`http://www.xyzniot.com:90${val.image}`"
                         style="width: 100%; height: auto"
                    />

                  </div>
                  <div class="layui-elip text">{{ val.title}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="el-pages">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pageSize"
              layout="total,prev,pager,next"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </transition>
    </div>
    <nav-footer />
  </div>
</template>
   <script>
import NavHeader from "@/components/NavHeaders.vue";
import NavFooter from "@/components/NavFooter.vue";
export default {
  name: "about",
  data() {
    return {
      Factorys: [],
      currentPage: 1, // 当前页码
      pageSize: 12, // 每页显示的行数
      total: 0,
      loading: true
    };
  },
  components: {
    NavHeader,
    NavFooter,
  },
  mounted() {
    this.getFactorys();
  },
  created() {
    document.documentElement.scrollTop = "0px";

  },
  methods: {
    goPages(id) {
      this.$router.push('/detail/'+id);
    },
    handleCurrentChange(val) {

      this.currentPage = val;
      let self = this;
      this.$api.mall
        .getNews({
          limit: 12,
          type: "19",
          page: this.currentPage,
        })
        .then((ress) => {
          let res = ress.data.data.data;
          self.Factorys = res;
          document.documentElement.scrollTop = "0px";
          this.total = ress.data.data.total;

        });
    },
    getFactorys() {
      let self = this;
      this.$api.mall
        .getNews({
          limit: 12,
          page: 1,
          type: "19",
        })
        .then((ress) => {
          this.loading = false
          let res = ress.data.data.data;
          self.Factorys = res;
          this.total = ress.data.data.total;
        });
    },
  },
};
</script>
  <style lang="scss" scoped>
.factory {
  width: 100%;
  overflow: hidden;
  margin: 0px auto;
  .v-enter {
    opacity: 0;
    transform: translateY(150px);
  }
  .v-enter-active {
    transition: all 1s ease;
  }
  @media screen and(max-width:768px){
    .contents{
      padding-top: 60px!important;
      min-width: unset!important;
    }
    .waps{
      width: calc(100% - 20px)!important;
      max-width: unset!important;
    }
    .pageBox{
      padding: 15px 0!important;
    }
    .Factorys-items{
      width: 47%!important;
      height: 140px!important;
      margin-left: 10px!important;
      margin-bottom:15px!important;
    }
    .factory_item{
      padding: 10px!important;
      width: calc(100% - 20px)!important;
      height: calc(100% - 20px)!important;
    }
    .text{
      width: calc(100% - 20px)!important;
      left: 10px!important;
    }
  }
  .contents {
    width: 100%;
    overflow: hidden;
    margin: 0px auto;
    padding-top: 75px;
    .el-pages {
      width: 100%;
      margin: 28px auto;
      overflow: hidden;
      text-align: center;
    }
    .waps {
      width: 90%;
      max-width: 1200px;
      overflow: hidden;
      margin: 0 auto;
    }
    .pageBox {
      padding: 60px 0;
      .Factorys-centers {
        width: 100%;
        overflow: hidden;
        margin: 0px auto;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        .Factorys-items {
          width: 31%;
          overflow: hidden;
          position: relative;
          height: 220px;
          margin-left: 25px;
          margin-bottom: 30px;
          box-sizing: border-box;
          box-shadow: 0 0 10px 0 #ddd;
          .factory_item{
            cursor: pointer;
            padding: 15px;
            width: calc(100% - 30px);
            height: calc(100% - 30px);
          }
          .text {
            position: absolute;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            bottom: 15px;
            width: calc(100% - 30px);
            left: 15px;
            height: 38px;
            color: #fff;
            text-align: center;
            background-color: rgba(0, 0, 0, 0.5);
            line-height: 38px;
          }
        }
      }
      .gsjs {
        min-height: 150px;
        transform: translate(0, 0px);
        opacity: 1;
        transition: 1.3s;
        -webkit-transition: 1.3s;
        .imgs {
          float: left;
          width: 420px;
          overflow: hidden;
        }
        .txts {
          float: right;
          width: calc(100% - 480px);
          line-height: 2em;
          font-family: 微软雅黑;
          font-size: 16px;
          color: #000000;
        }
        .txt {
          float: right;
          width: calc(100% - 480px);
          line-height: 1.5em;
        }
      }
      .pagetits {
        font-size: 26px;
        text-align: center;
        font-weight: bold;
        line-height: 1.2em;
        padding-bottom: 3%;
        color: #333333;
      }

      .pagetit {
        font-size: 26px;
        text-align: center;
        font-weight: bold;
        line-height: 1.2em;
        padding-bottom: 3.5%;
        color: #333333;
      }
    }
    .in-banner {
      width: 100%;
      overflow: hidden;
      margin: 0px auto;
    }
  }
}
</style>
