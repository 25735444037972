<template>
  <div class="about">
    <nav-header :navs="7" :navsitems="21" />
    <div class="contents">
      <div class="in-banner">
        <img
          src="http://www.jiukaist.com/images/banner/11.png"
          width="100%"
          height="auto"
        />
      </div>

      <transition>
        <div class="pageBox waps">
          <div class="pagetit">公司简介</div>
          <div class="gsjs clearfix animation srcospcur">
            <div class="imgs">
              <img
                src="../assets/img/wencity.png"
                width="100%"
              />
            </div>
            <div class="txts">
              <span style="color: #000000;font-size: 16px;line-height: 2em;font-family: 微软雅黑;">
                台州芯远智能科技有限公司于2019年注册成立，位于浙江省台州市，
                是一家物联网产品及其配套软件推广企业。
                公司主要产品分为：物联网数据网关，传感器，输入输出，信号采集，智能照明，物联网开关，通信转换，物联网能耗监测，智慧型采集终端等。
              </span>
            </div>
          </div>
        </div>
      </transition>
      <transition>
        <div class="pageBox waps">
          <div class="pagetit">研发实力</div>

          <div class="gsjs clearfix animation srcospcur">
            <div class="imgs">
              <img
                src="http://www.jiukaist.com/images/banner/yanfashili.jpg"
                width="100%"
              />
            </div>
            <div class="txts">
              <span>
                公司拥有一支年纪轻、素质高、肯吃苦的科研队伍，具有丰富的物联网产品研发经验，
                并配备了国内外先进的测试仪器、设备。我们不但具备产品自主研发能力，同时也是产品制造商，
                拥有完整的物联网数据网关，传感器系列产品研发、设计与制造能力，
                该综合能力决定了我们深刻理解物联网产品的底层以及衍生配套软件，能够快速响应用户需求，
                并提供优越的解决方案以及无可比拟的竞争优势。
              </span>
            </div>
          </div>
        </div>
      </transition>


    </div>
    <nav-footer />
  </div>
</template>
<script>
import NavHeader from "@/components/NavHeaders.vue";
import NavFooter from "@/components/NavFooter.vue";
export default {
  name: "about",
  data() {
    return {};
  },
  components: {
    NavHeader,
    NavFooter,
  },
  mounted() {},
  created() {
    document.documentElement.scrollTop = "0px";
  },
  methods: {},
};
</script>

<style lang='scss' scoped>
.about {
  width: 100%;
  overflow: hidden;
  margin: 0px auto;
  .v-enter {
    opacity: 0;
    transform: translateY(150px);
  }
  .v-enter-active {
    transition: all 1s ease;
  }
  @media screen and(max-width:768px) {
    .contents {
      padding-top: 60px !important;
    }
    .pageBox{
      padding: 10px 0!important;
      width: calc(100% - 20px)!important;
    }
    .pagetit{
      font-size: 20px!important;
    }
    .imgs{
      float:unset!important;
      width: 100%!important;
    }
    .txts{
      float: unset!important;
      width: 100%!important;
      span{
        font-size: 14px!important;
      }
    }
  }
  .contents {
    width: 100%;
    overflow: hidden;
    margin: 0px auto;
    padding-top: 75px;
    .waps {
      width: 90%;
      max-width: 1200px;
      overflow: hidden;
      margin: 0 auto;
    }
    .pageBox {
      padding: 4% 0 2%;
      width: 100%;
      overflow: hidden;
      box-sizing: border-box;
      .gsjs {
        transform: translate(0, 0px);
        opacity: 1;
        transition: 1.3s;
        -webkit-transition: 1.3s;
        .imgs {
          float: left;
          width: 420px;
          overflow: hidden;
        }
        .txts {
          float: right;
          width: calc(100% - 480px);
          line-height: 2em;
          font-family: 微软雅黑;
          font-size: 16px;
          color: #000000;
          text-indent: 2rem;
        }
      }

      .pagetit {
        font-size: 26px;
        text-align: center;
        font-weight: bold;
        line-height: 1.2em;
        padding-bottom: 3.5%;
        color: #333333;
      }
    }
    .in-banner {
      width: 100%;
      overflow: hidden;
      margin: 0px auto;
    }
  }
}
</style>
