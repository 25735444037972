<template>
  <div class="factorydetail">
    <nav-header :navs="2" :navsitems="2"/>
    <div class="contents">
      <div class="in-banner" style="border-bottom: 1px solid #dcdcdc">
        <img
          src="http://www.xyzniot.com:90/uploads/20231226/963e6eb7870afd2beaac5f2eb114f530.jpg"
          width="100%"
          height="auto"
        />
      </div>

      <div class="pageBox waps" style="background: #fff;">
        <div class="pagetits">安耐杰云展示</div>
        <div class="gsjs clearfix animation srcospcur">
          <h3 style="text-align:center;margin-bottom:20px">
            <span style="color: #008000">
              <strong>
                <span style="font-size: 20px;">项目背景</span>
              </strong>
            </span>
          </h3>
          <div style="padding: 5px 10px">
            <span style="font-size: 16px"
              >&nbsp; &nbsp; &nbsp;
              电能已成为社会的基础能源，随着用电负荷的不断攀升及电气设备数量的日益剧增，安全、稳定、节能等用电问题就变得至关重要。然而在目前的用电过程中，设备运行维护及管理仍然依靠人工，效率低，效果差！常见痛点问题主要表现在：
              <br />电气安全：
              无法及时发现设备故障隐患，故障停电、电气火灾等安全事故频发。
              <br />能源管理：依靠人工统计，很难发现漏洞。
              <br />运行维护：故障维修响应慢，巡检过程难以管控，设备全生命周期管理缺失。</span
            >
          </div>
          <div style="text-align: center">&nbsp;</div>
          <div style="text-align: center">
            <a
              class="fluidbox fluidbox__instance-1 fluidbox--initialized fluidbox--closed fluidbox--ready"
              href="http://www.jiukaist.com/images/platpht/197c1d1f89119048c1eccdbfc1f51857.jpg"
              data-fluidbox=""
              data-fluidbox-loader="true"
            >
              <div class="fluidbox__wrap" style="z-index: 1">
                <img
                  alt=""
                  src="http://www.jiukaist.com/images/platpht/197c1d1f89119048c1eccdbfc1f51857.jpg"
                  class="fluidbox__thumb"
                  style="opacity: 1;width: 100%;height:auto"
                />
                <div></div>
                <div class="fluidbox__loader" style="z-index: 2"></div>
              </div>
            </a>
          </div>
          <div>
            <span style="font-size: 16px">&nbsp; &nbsp; &nbsp;</span>&nbsp;
            &nbsp;<span style="font-size: 18px"
              >政策加持，助力行业加速发展：</span
            >
            <span style="font-size: 16px"
              >2020年10月10日，工业和信息化部、应急管理部印发《“工业互联网+安全生产”
              行动计划（2021-2023年）》指出：“构建基于工业互联网的安全感知、监测、预警处置及评估体系，提升企业安全生产的数字化、网络化、智能化水平”；&nbsp;国家电力发展十三五规划
              （2016~2020）要求：1.升级改造配电网，推进智能电网建设；2.全面建设智能变电站，推广应用在线监测、状态诊断、智能巡检系统，3.加强发输配用交互响应能力建设，构建“互联网+”智能电网。</span
            >
          </div>
          <h3 style="text-align:center;margin-bottom:20px">
            <br />
            <span style="color: #008000">
              <strong>
                <span style="font-size: 20px">安耐杰云智慧电力物联网</span>
              </strong>
            </span>
          </h3>
          <p>
            &nbsp; &nbsp;&nbsp;<span style="font-size: 16px"
              >安耐杰云智慧电力物联网是以提高电力运行安全，降低运维成本为目标，采用物联网、云计算技术，对配电室、箱式变电站、配电箱(柜)等电力设备数字化升级，建设用户侧电力系统物联网。同时借助大数据、人工智能等现代信息技术，通过智能终端、监控大屏、移动APP等实现电力系统智能化运维，根据客户需求的不同，为业主、制造商、维保商提供有针对性的数据服务及平台系统！有效保障电力设备运行的安全、可控、高效！是电力系统运维管理工作的现代化工具。</span
            >
          </p>
          <p style="text-align: center">
            <a
              class="fluidbox fluidbox__instance-2 fluidbox--initialized fluidbox--closed fluidbox--ready"
              href="http://www.jiukaist.com/images/platpht/32a9f7302cde83da08f3aed074edb092.png"
              data-fluidbox=""
              data-fluidbox-loader="true"
            >
              <div class="fluidbox__wrap" style="z-index: 1">
                <img
                  alt=""
                  src="http://www.jiukaist.com/images/platpht/32a9f7302cde83da08f3aed074edb092.png"
                  class="fluidbox__thumb"
                  style="opacity: 1;width: 100%;height:auto"
                />
                <div class="fluidbox__loader" style="z-index: 2"></div>
              </div>
            </a>
          </p>
          <p>
            <span style="font-size: 16px"
              >&nbsp;&nbsp;&nbsp;
              通过智能采集终端与通信设备，实时将电气参数、运行信息和环境数据传送至智慧电力物联网平台—电易云，对配电室、箱式变电站、现场配电箱（柜）进行数字化升级，对运维工作数字化升级，建设电力系统物联网。</span
            >
          </p>
          <div class="fluidbox__wrap" style="z-index: 1">
            <img
              alt=""
              src="http://www.jiukaist.com/images/platpht/713e47148d38a6c2d4c8610048a19704.png"
              class="fluidbox__thumb"
              style="opacity: 1;width: 100%;height:auto"
            />
            <div class="fluidbox__loader" style="z-index: 2"></div>
          </div>
          <h3 style="text-align:center;margin-bottom:20px">
            &nbsp;
            <span style="color: #008000"
              >&nbsp;<strong>
                <span style="font-size: 20px">实现功能</span>
              </strong>
            </span>
          </h3>
          <p>
            <span style="font-size: 16px"
              >安耐杰依托电力物联网技术搭建电易云智能化管理平台，云平台集成电力需求管理功能，平台智能化管控。平台实现24小时实时监控，对用户的用电设备状况全感知，做到防患未然，运用云计算等数字化运算模型，对生产能耗提供科学决策，为用户提供全流程、多维度的安全用电、高效用电、经济用电的数字化智慧用电解决方案。
              <br />
              <strong>1、电力运行与电气安全监控</strong>
            </span>
          </p>
          <p>
            <span style="font-size: 16px"
              >　　实时在线监测电气设备电流、电压、漏电、温度及开关状态等信息，发现异常及时报警，有效保障设备的安全可靠运行。</span
            >
          </p>
          <p>
            <a
              class="fluidbox fluidbox__instance-4 fluidbox--initialized fluidbox--closed fluidbox--ready"
              href="http://www.jiukaist.com/images/platpht/89216a5a6784e5d121ef1d72294e33b9.jpg"
              data-fluidbox=""
              data-fluidbox-loader="true"
            >
              <div class="fluidbox__wrap" style="z-index: 1">
                <img
                  alt=""
                  src="http://www.jiukaist.com/images/platpht/89216a5a6784e5d121ef1d72294e33b9.jpg"
                  class="fluidbox__thumb"
                  style="opacity: 1;width: 100%;height:auto"
                />
                <div class="fluidbox__loader" style="z-index: 2"></div>
              </div>
            </a>
          </p>
          <h4>
            <span style="font-size: 16px">
              <strong>2、环境安全监测</strong>
            </span>
          </h4>
          <p>
            　　&nbsp;
            <span style="font-size: 16px"
              >实时监测配电室的运行环境，包括温湿度、水浸、门磁、烟感、视频，保障设备运行环境的安全。</span
            >
          </p>
          <p>
            <a
              class="fluidbox fluidbox__instance-5 fluidbox--initialized fluidbox--closed fluidbox--ready"
              href="http://www.jiukaist.com/images/platpht/6973e6b91fe441021a7272c0a11d2b78.jpg"
              data-fluidbox=""
              data-fluidbox-loader="true"
            >
              <div class="fluidbox__wrap" style="z-index: 1">
                <div class="fluidbox__loader" style="z-index: 2"></div>
              </div>
            </a>
          </p>
          <h4>
            <span style="font-size: 16px">
              <strong>3、电能管理</strong>
            </span>
          </h4>
          <p>
            <span style="font-size: 16px"
              >　　按照电力设备对象分周期(日/周/月/年)统计电能数据及最大需量发生值，并进行同环比分析;按尖、峰、平、谷统计各配电回路的用电量;自动生成电能集抄报表。</span
            >
          </p>
          <p>
            <a
              class="fluidbox fluidbox__instance-6 fluidbox--initialized fluidbox--closed fluidbox--ready"
              href="http://www.jiukaist.com/images/platpht/58482313c60ba29f542c240ea6f993e2.jpg"
              data-fluidbox=""
              data-fluidbox-loader="true"
            >
              <div class="fluidbox__wrap" style="z-index: 1">
                <img
                  alt=""
                  src="http://www.jiukaist.com/images/platpht/58482313c60ba29f542c240ea6f993e2.jpg"
                  class="fluidbox__thumb"
                  style="opacity: 1;width: 100%;height:auto"
                />
                <div class="fluidbox__loader" style="z-index: 2"></div>
              </div>
            </a>
          </p>
          <p>
            <span style="font-size: 16px"
              >　　按照配电回路、区域、部门、分项(照明、空调、动力等)统计用电数据，统计各配电回路的用电量及用电金额;按月统计每个回路的最大需量及发生值，为需量申报提供依据;</span
            >
          </p>
          <p>
            <a
              class="fluidbox fluidbox__instance-7 fluidbox--initialized fluidbox--closed fluidbox--ready"
              href="http://www.jiukaist.com/images/platpht/e5448d549b49a389536f90d8125f73cb.jpg"
              data-fluidbox=""
              data-fluidbox-loader="true"
            >
              <div class="fluidbox__wrap" style="z-index: 1">
                <img
                  alt=""
                  src="http://www.jiukaist.com/images/platpht/e5448d549b49a389536f90d8125f73cb.jpg"
                  class="fluidbox__thumb"
                  style="opacity: 1;width: 100%;height:auto"
                />
                <div class="fluidbox__loader" style="z-index: 2"></div>
              </div>
            </a>
          </p>
          <h4>
            <span style="font-size: 16px">
              <strong>4、一站式数字化运维</strong>
            </span>
          </h4>
          <p>
            <span style="font-size: 16px"
              >　　安耐杰云实现了电力设备巡检、维修、保养等工作的智能化、留痕化。可随时随地查询设备台账信息，运行时间、图纸资料、维修记录等，
              电易云是现场维护人员的智能化工具。</span
            >
          </p>
          <p>
            <a
              class="fluidbox fluidbox__instance-8 fluidbox--initialized fluidbox--closed fluidbox--ready"
              href="http://www.jiukaist.com/images/platpht/4454672c92ef646229afc7e2120dc340.jpg"
              data-fluidbox=""
              data-fluidbox-loader="true"
            >
              <div class="fluidbox__wrap" style="z-index: 1">
                <img
                  alt=""
                  src="http://www.jiukaist.com/images/platpht/4454672c92ef646229afc7e2120dc340.jpg"
                  class="fluidbox__thumb"
                  style="opacity: 1;width: 100%;height:auto"
                />
                <div class="fluidbox__loader" style="z-index: 2"></div>
              </div>
            </a>
          </p>
          <p>
            <span style="font-size: 16px"
              >　　安耐杰云对设备运行数据、维护数据进行统分析并形成报表，为管理人员的设备维修业务决策、人员绩效评价提供数据支撑，电易云是管理人员的智能化工具。</span
            >
          </p>
          <p>
            <a
              class="fluidbox fluidbox__instance-9 fluidbox--initialized fluidbox--closed fluidbox--ready"
              href="http://www.jiukaist.com/images/platpht/3a2ea1f3d006037a548627f4d5630866.jpg"
              data-fluidbox=""
              data-fluidbox-loader="true"
            >
              <div class="fluidbox__wrap" style="z-index: 1">
                <img
                  alt=""
                  src="http://www.jiukaist.com/images/platpht/3a2ea1f3d006037a548627f4d5630866.jpg"
                  class="fluidbox__thumb"
                  style="opacity: 1;width: 100%;height:auto"
                />
                <div class="fluidbox__loader" style="z-index: 2"></div>
              </div>
            </a>
          </p>
          <h3 style="text-align:center;margin-bottom:20px">
            <br />
            <span style="font-size: 20px">
              <span style="color: #008000">
                <strong>适用用户</strong> </span
              >　
            </span>
            <span style="font-size: 16px">　</span>
          </h3>
          <p>
            <span style="font-size: 16px"
              >&nbsp; &nbsp; &nbsp;<span style="color: #008000"
                >建筑用户：医院、学校、机场、车站、酒店、商场、办公大楼、体育馆、会展中心、博物馆、综合楼等;</span
              >
            </span>
          </p>
          <p>
            <span style="color: #008000">
              <span style="font-size: 16px"
                >工业用户：化工厂、冶金厂、制药厂、纺织厂、煤矿厂、食品厂、汽车制造厂、木材加工厂等。</span
              >
            </span>
          </p>
        </div>
        <div class="xqpage">
          <!--
          <div class="res" @click="backs()">返回</div>-->
        </div>
      </div>
    </div>
    <nav-footer />
  </div>
</template>
     <script>
import NavHeader from "@/components/NavHeaders.vue";
import NavFooter from "@/components/NavFooter.vue";
export default {
  name: "about",
  data() {
    return {
      Factorys: {
        title: "",
        maincontent: "",
      },
      id: "",
      currentPage: 1, // 当前页码
      pageSize: 12, // 每页显示的行数
      total: 0,
    };
  },
  components: {
    NavHeader,
    NavFooter,
  },
  mounted() {},
  created() {
    let self = this;
    document.documentElement.scrollTop = "0px";
    //self.init();
  },
  methods: {
    backs() {
      history.go(-1);
    },
    init() {
      let self = this;
      this.$api.mall
        .getNewdetail({
          id: self.id,
        })
        .then((ress) => {
          let res = ress.data.data;
          console.log(res, "Ssbbb");
          self.Factorys = res;
        });
    },
  },
};
</script>
    <style lang="scss" scoped>
.factorydetail {
  width: 100%;
  overflow: hidden;
  margin: 0px auto;
  .v-enter {
    opacity: 0;
    transform: translateY(150px);
  }
  .v-enter-active {
    transition: all 1s ease;
  }
  .contents {
    width: 100%;
    overflow: hidden;
    margin: 0px auto;
    padding-top: 75px;

    .xqpage {
      width: 100%;
      margin-top: 30px;
      overflow: hidden;
      border-top: 1px dashed #dcdcdc;
      .res {
        display: block;
        margin-top: 30px;
        margin-bottom: 20px;
        width: 80px;
        line-height: 30px;
        border-bottom: 1px solid #72a3ce;
        background: #005bac;
        color: #fff;
        cursor: pointer;
        font-size: 12px;
        text-align: center;
      }
    }
    .el-pages {
      width: 100%;
      margin: 20px auto;
      overflow: hidden;
      text-align: center;
    }
    .waps {
      width: 90%;
      max-width: 1200px;
      overflow: hidden;
      margin: 0 auto;
    }
    .pageBox {
      padding: 4% 0 2%;
      width: 100%;
      line-height: 32px;
      overflow: hidden;
      box-sizing: border-box;
      .gsjs {
        transform: translate(0, 0px);
        width: 680px;
        overflow: hidden;
        margin: 0px auto;

        opacity: 1;
        transition: 1.3s;
        -webkit-transition: 1.3s;
        .imgs {
          float: left;
          width: 420px;
          overflow: hidden;
        }
        .txts {
          float: right;
          width: calc(100% - 480px);
          line-height: 2em;
          font-family: 微软雅黑;
          font-size: 16px;
          color: #000000;
        }
        .txt {
          float: right;
          width: calc(100% - 480px);
          line-height: 1.5em;
        }
      }
      .pagetits {
        font-size: 26px;
        text-align: center;
        font-weight: bold;
        line-height: 1.2em;
        padding-bottom: 3%;
        color: #333333;
      }

      .pagetit {
        font-size: 26px;
        text-align: center;
        font-weight: bold;
        line-height: 1.2em;
        padding-bottom: 3.5%;
        color: #333333;
      }
    }
    .in-banner {
      width: 100%;
      overflow: hidden;
      margin: 0px auto;
    }
  }
}
</style>
