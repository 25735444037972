<template>
  <div id="app">
    <router-view style="height: 100%"></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {
      res: {}
    };
  },
  mounted() {
    // 本地加载请求静态json文件的形式
    // this.axios.get('/json/user/login.json').then((res) => {
    //   this.res = res;
    // })
    // 通过easy-mock平台实现mock
    // 将axios中axios.defaults.baseURL = ''地址换为easy-mock项目地址
    // this.axios.get('/user/login').then((res) => {
    //   this.res = res;
    // })
    // 通过mockjs实现mock
    // this.axios.get('/user/login').then((res) => {
    //   this.res = res;
    // })
    if (this.$cookie.get('userId')) {
      this.getUserInfo();
      this.getCartSum();
    }
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      this.$api.mall.getUserInfo({}).then((res = {}) => {
        // 保存到vuex里面
        this.$store.dispatch('saveUserName', res.username);
      });
    },
    // 获取购物车商品数量
    getCartSum() {
      this.$api.mall.getCartSum({}).then((res = 0) => {
        // 保存到vuex里面
        this.$store.dispatch('saveCartCount', res);
      });
    }
  }
};
</script>
<style>
video::-internal-media-controls-download-button {
    display:none;
}
video::-webkit-media-controls-enclosure {
    overflow:hidden;
}
video::-webkit-media-controls-panel {
    width: calc(100% + 30px);
}
</style>
<style lang="scss">
// @import '@/assets/scss/index.scss'; // 引入重置样式表（覆盖浏览器用户代理样式）
// @import '@/assets/scss/modal.scss'; // 引入模态框样式表
#app{
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.el-tabs{
  .el-tabs__header{
    margin: 30px 0 50px;
  }
  .el-tabs__item{
    font-size: 20px!important;
    font-weight: bold;
    padding: 0!important;
    width: 150px;
    text-align: center;
  }
  .el-tabs__item.is-active {
    color: #fff!important;
    background: #207DBA!important;
    text-align: center;
    border-radius: 20px;
  }
  .el-tabs__active-bar{
    background-color: #fff;
  }
  .el-tabs__item:hover{
    color: #207DBA;
  }
  .is-active:hover {
    color:#fff!important;
  }
  .el-tabs__content{
    overflow: unset;
  }
}
@media screen and(max-width:768px){
  .el-tabs{
    .el-tabs__header{
      margin: 20px 0!important;
    }
    .el-tabs__item{
      font-size: 16px!important;
      font-weight: bold;
      padding: 0!important;
      width: 110px;
    }
    .el-tabs__item.is-active {
      color: #fff!important;
      background: #207DBA!important;
      text-align: center;
      border-radius: 20px;
    }
  }
}



</style>
