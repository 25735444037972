
<template>
  <div class="product_type_lists">
    <nav-header :navs="4" :navsitems="a" />
    <div class="product_type_centers">

      <div class="g-proList-box">
        <div class="top_local_option">
          <span v-if="$device.windows">当前位置： </span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/product_centers' }">产品中心</el-breadcrumb-item>
            <el-breadcrumb-item>{{menuName}}</el-breadcrumb-item>
            <el-breadcrumb-item v-if="submenuName">{{submenuName}}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="g-foot_box">
          <div class="g-order_list_box">
            <div class="productions_item" v-for="item in Factorys" :index="item.id" :key="item.id">
              <!--判断一级菜单有子菜单-->
              <div v-if="item.lists.length>0">
                <div
                    class="c1"
                    :class="{ oncolor: xxindex == item.id }"
                    :title="item.name"
                    @click="showNavType(item)"
                >{{item.name}}</div>
                <div v-if="activeIndex == item.id" v-show="itemshow">
                  <div v-for="subitem in item.lists" :index="subitem.id" :key="subitem.id">
                    <!--判断有三级菜单-->
                    <div v-if="subitem.listss.length>0">
                      <div class="c2"
                           :class="{ actcolor: yyindex == subitem.id }"
                           :title="subitem.name"
                           @click="getPro('grade2',subitem)">
                        <span> -  {{subitem.name}}</span>
                      </div>
                    </div>
                    <!--二级菜单没有子菜单-->
                    <div v-else>
                      <div class="c2" :class="{ actcolor: yyindex == subitem.id }" :title="subitem.name" @click="getPro('grade2',subitem)">{{subitem.name}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <!--一级菜单没有子菜单-->
              <div v-else>
                <div
                    class="c1"
                    :class="{ oncolor: xxindex == item.id }"
                    :title="item.name"
                    @click="getPro('grade1',item)"
                >{{item.name}}</div>
              </div>
            </div>
          </div>
          <div class="g-proList-r">
            <div class="right-top-input" v-if="$device.windows">
              <el-input v-model="param.title" placeholder="产品搜索"></el-input>
              <el-button type="primary" plain @click="SearchBtn">搜索</el-button>
            </div>

            <div class="g-proList-r-box" v-loading="loading">
              <div v-if="product.length>0 && product!=''">
                <div
                    class="proList-box-item"
                    v-for="(val, index) in product"
                    :key="index"
                    :index="val.id"
                    @click="openlink(val.id)"
                >
                  <div class="g-proList-r-img public-img g-img-hover">
                    <img v-if="val.image.indexOf('https') != -1"
                         :src="val.image"
                         :alt="val.title"
                    />
                    <img v-else
                         :src="'http://www.xyzniot.com:90/'+val.image"
                         :alt="val.title"
                    />
                  </div>
                  <div class="g-proList-r-txt">
                    <div class="g-font18">{{ val.title }}</div>
                    <div class="g-proList-r-p">{{val.basicfunction || '--'}}</div>
                  </div>
                </div>
              </div>
              <div v-else class="nodata">暂无数据</div>

              <div style="margin: 15px ">
                <el-pagination
                    background
                    @size-change="getProduct()"
                    @current-change="handleSizeChange"
                    :current-page.sync="param.page"
                    :page-size="param.pagesize"
                    layout="total, prev, pager, next"
                    :total="total">
                </el-pagination>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <nav-footer />
  </div>
</template>
    <script>
import NavHeader from "@/components/NavHeaders.vue";
import NavFooter from "@/components/NavFooter.vue";
export default {
  name: "product",
  components: {
    NavHeader,
    NavFooter,
  },
  data() {
    return {
      a: "",
      menuName:'物联网数据网关（DTU）',//一级菜单名称
      submenuName:'',//二级菜单名称
      testnemu:'',//预存名称
      product: [], //产品列表
      Factorys: [],//菜单
      itemshow:true,//展示二级菜单
      activeIndex:this.$route.params.firstid,//根据头部参数的一级菜单id展开二级菜单
      subIndex:0,
      xxindex:this.$route.params.firstid,//一级当前选中
      yyindex:123,//二级当前选中
      theid:this.$route.params.firstid,//路径参数
      secondid:'',//当前一级点击的菜单
      param:{
        page:1,
        pagesize:10,
        title:"",
        type:''
      },
      total:0,
      loading:true
    };
  },
  mounted() {
    let self = this;
    self.getFactorys();

  },
  methods: {
    showNavType( item) {

        //点击一级菜单
        this.activeIndex = item.id;
        this.xxindex = item.id;
        this.testnemu = item.name;
        if(this.theid == item.id){
          if(this.itemshow){
            this.itemshow = false;
          }else {
            this.itemshow = true;
          }
        }else {
          this.theid = item.id
          this.itemshow = false;
          if(this.itemshow){
            this.itemshow = false;
          }else {
            this.itemshow = true;
          }
        }

        this.menuName = item.name
        this.submenuName = ''
        this.subIndex = item.id;
        localStorage.setItem("pid",item.pid);//存储一级菜单id
        localStorage.setItem("menuName",this.menuName);//存储一级菜单名称

    },
    getPro(grade,item){
      this.menuName = this.testnemu;

      if(grade=="grade1"){
        this.xxindex = item.id;
        this.menuName = item.name
        this.submenuName = ''
        localStorage.setItem("pid",item.id);//存储一级菜单id
        localStorage.setItem("menuName",item.name);//存储一级菜单名称
      }else if(grade == "grade2"){
        this.yyindex = item.id;
        this.submenuName = item.name;
        localStorage.setItem("pid",item.pid);//存储一级菜单id
        localStorage.setItem("menuName",this.menuName);//存储一级菜单名称
        localStorage.setItem("sid",item.id);//存储二级菜单id
        localStorage.setItem("submenuName",item.name);//存储二级菜单名称
      }
      this.param.page = 1
      this.param.type = item.id
      this.getProduct()
    },
    // 获取菜单
    getFactorys() {
      let self = this;
      this.$api.mall
          .getCategory()
          .then((response) => {
            let respon = response.data.data;

            self.Factorys = respon;
            self.firstChange(respon)
          });
    },
    firstChange(List){
      let self = this;
      List.forEach(item => {
        console.log(item,'555',self.theid,localStorage.getItem('sid'))
        if( item.id == self.theid ){

          self.menuName = item.name
          self.testnemu = item.name

          localStorage.setItem("pid",item.id);//存储一级菜单id
          localStorage.setItem("menuName",item.name);//存储一级菜单名称
          if(localStorage.getItem("pid")){
            if(localStorage.getItem("pid") == self.theid){

              item.lists.forEach((sitem,index) => {

                console.log(sitem,'666')
                if(localStorage.getItem("sid")){
                  if(localStorage.getItem("sid") == sitem.id){

                      self.Conditionqest(item,sitem)

                  }
                }else {
                  if(index == 0){
                    self.kidConditionqest(item,sitem)
                  }
                }
              })
            }else {
              item.lists.forEach((sitem,index) => {

                if(index == 0){
                  self.Conditionqest(item,sitem)
                }
              })
            }
          }else {
            item.lists.forEach((sitem,index) => {
              if(index == 0){
                self.Conditionqest(item,sitem)
              }
            })
          }
        }else{
          item.lists.forEach(sitem => {
            if(sitem.id == self.theid){

              self.menuName = item.name
              self.testnemu = item.name
              self.activeIndex = sitem.pid
              self.itemshow = true
              localStorage.setItem("pid",item.id);//存储一级菜单id
              localStorage.setItem("menuName",item.name);//存储一级菜单名称
              self.kidConditionqest(item,sitem)
            }
          })
        }
      })
    },
    kidConditionqest(item,sitem){
      const self = this;
      self.yyindex = sitem.id
      if(item.id == 163){

        self.submenuName = sitem.name
        self.subIndex = sitem.id
        self.param.page = 1
        self.param.type = sitem.id
        self.getProduct();
      }else {
        self.submenuName = sitem.name
        self.param.page = 1
        self.param.type = sitem.id
        self.getProduct();
      }
      localStorage.setItem("sid",sitem.id);//存储二级菜单id
      localStorage.setItem("submenuName",sitem.name);//存储二级菜单名称


    },
    Conditionqest(item,sitem){
      const self = this;
      self.yyindex = sitem.id
      if(item.id == 163){
        self.submenuName = sitem.name;
        self.subIndex = sitem.id;
        self.param.page = 1;
        self.param.type = sitem.listss[0].id;
        self.getProduct();
      }else {
        self.submenuName = sitem.name
        self.param.type = sitem.id
        self.param.page = 1
        self.getProduct();
      }
      localStorage.setItem("sid",sitem.id);//存储二级菜单id
      localStorage.setItem("submenuName",sitem.name);//存储二级菜单名称
    },

    //搜索
    SearchBtn(){
      this.param.type = ''
      this.$api.mall
          .getProductList({
            limit: this.param.pagesize,
            type: this.param.type,
            page: this.param.page,
            title:this.param.title
          })
          .then((ress) => {
            let res = ress.data.data.data;
            this.product = res;
            this.total = ress.data.data.total;
          });
    },
    handleSizeChange(val){
      var _that = this;
      _that.param.page = val;
      _that.getProduct()
    },
    //获取产品列表
    getProduct() {
      let self = this;
      console.log(self.param)
      this.$api.mall
        .getProductList({
          limit: self.param.pagesize,
          type: self.param.type,
          page: self.param.page,
          title: self.param.title,
        })
        .then((ress) => {
          let res = ress.data.data.data;
          this.product = res;
          this.total = ress.data.data.total;
          this.loading = false
        });
    },

    openlink(id){
      this.$router.push('/newdetail/'+id);
    },
  },
};
</script>
<style lang="scss">
.product_type_lists {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  .product_type_centers {
    width: 100%;
    min-height: calc(100% - 254px);
    background: #f5f5f5;
    padding: 0px;
    box-sizing: border-box;
    overflow: hidden;
    padding-top: 80px;
    margin: 0px auto;
    .g-proList-box {
      width: 1213px;
      overflow: hidden;
      margin: 20px auto;
      .nodata{
        color: #999;
        text-align: center;
      }
      .top_local_option{
        padding: 10px;
        background: #fff;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
      }
      .g-foot_box{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .g-order_list_box {
          width: 25%;
          padding-right: 5px;
          position: sticky;
          line-height: 2.8;
          top: 0;
          border-right: 1px solid #e2e2e2;
          .c1 {
            font-size: 16px;
            font-weight: bold;
            color: #333;
            padding: 0 10px;
            cursor: pointer;
          }
          .c1s {
            font-size: 16px;
            color: #333;
            cursor: pointer;
          }
          .c1:hover, .c2:hover, .c3:hover {
            background: #fff;
            color: #207DBA;
            //border-left: 2px solid #1d8d3b;
          }
          .oncolor{
            color: #207DBA;
          }
          .actcolor{
            background: #fff;
            color: #207DBA!important;
          }
          .c2 {
            box-sizing: border-box;
            cursor: pointer;
            width: 100%;
            font-size: 16px;
            color: #333;
            border-left: 3px solid transparent;
            padding-left: 28px;
          }
          .c3{
            box-sizing: border-box;
            cursor: pointer;
            width: 100%;
            font-size: 14px;
            border-left: 3px solid transparent;
            padding-left: 56px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .g-proList-r {
          width: calc(75% - 0.6rem);
          padding-left: 10px;
          box-sizing: border-box;
          overflow: hidden;
          .right-top-input{
            display: flex;
            align-items: center;
            margin-bottom: 15px;
          }

          .g-proList-r-box {
            width: 100%;
            overflow: hidden;
            flex-wrap: wrap;
            margin-bottom: 0.3rem;
            .public-img{
              width: 200px;
              height: 200px;
              overflow: hidden;
              img{
                width: 100%;
              }
            }
            img:hover {
              transition: all 0.3s;
              transform: scale(1.05);
              -webkit-transform: scale(1.05);
            }
            .proList-box-item:hover {
              box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
              border-left: 2px solid #207DBA;
            }
            .proList-box-item {
              width: 100%;
              //transition: all 0.3s;
              cursor: pointer;
              box-sizing: border-box;
              padding: 10px;
              overflow: hidden;
              background: #fff;
              margin-top: 12px;
              display: flex;
              align-items: center;
              .g-proList-r-txt{
                padding: 10px 50px;
                width: calc(100% - 250px);
              }
              .g-font18 {
                font-size: 18px;
                padding: 10px 0;
                color: #333;
                width: 100%;
                font-weight: bold;
                line-height: 1.5;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .g-proList-r-p{
                padding: 10px 0;
                border-bottom: 1px dashed #ddd;
              }
            }
          }
        }

      }

    }
  }
  @media screen and(max-width:768px){
    .product_type_centers{
      padding-top:60px!important;
      min-height:unset!important;
    }
    .el-breadcrumb{
      line-height: 1.5!important;
    }
    .g-proList-box{
      width: calc(100% - 20px)!important;
      margin: 10px auto!important;
    }
    .top_local_option{
      margin-bottom: 10px!important;
    }
    .g-foot_box{
      display: block!important;
    }
    .g-order_list_box{
      width: 100%!important;
      background: #fff;
    }
    .g-proList-r{
      width: 100%!important;
      padding-left:0!important;
    }
    .proList-box-item{
      display: block!important;
      text-align: center;
    }
    .productions_item{
      border-bottom: 1px dashed #ddd;
    }
    .g-proList-r-p{
      padding: 0!important;
      padding-bottom: 10px!important;
    }
    .public-img{
      width: 100%!important;
      height: 150px!important;
      text-align: center;
      img{
        width: 150px!important;
        height: 150px!important;
      }
    }
    .g-proList-r-txt{
      padding: 10px!important;
      width: 100%!important;
    }

  }
}
</style>
